.addValueButton {
    height: 45px;
    width: 100%;
    text-align: left;
    border: 0;
    background: none;
    color: var(--prymary);
    font-size: 14px;
    outline: 0;
}

.addValueButton svg {
    width: 24px;
    height: 24px;
}

.historyLabel {
    font-size: 14px;
}

.historyLabelInfos {
    font-size: 12px;
}

.fieldContainer {
    min-height: 45px;
}

.debug {
    font-size: 12px;
    font-weight: bold;
    padding: 3px;
}

.debugError {
    color: white;
    background: red;
}

.debugInfo {
    color: black;
    background: lightgray;
}

.debugOk {
    color: white;
    background: green;
}

.availableCharsLabel {
    opacity: 0.5;
    font-size: 14px;
    font-weight: 300;
}

.inputInvalidText {
    font-size: 14px;
    color: var(--adi-red);
    position: absolute;
    background: white;
    font-style: italic;
    z-index: 2;
    width: 200px;
}

.inputLabelAlert {
    font-size: 14px;
    font-weight: bold;
    color: black;
    position: absolute;
    background: white;
    z-index: 2;
}

.inputMissingValue {
    font-size: 14px;
    color: var(--adi-red);
    background: white;
    font-style: italic;
    font-weight: 300;
}

.phonePrefix {
    background-color: #F0F0F0;
    border: 1px solid var(--prymary);
    border-radius: 10px;
    margin-right: 1px;
}

.phonePrefix label {
    margin: 9px 5px 0;
    color: black;
}

.clickable {
    cursor: pointer;
}

.iconColor {
    fill: var(--prymary)
}

/* HISTORY SIDEBAR */

.modal {
    opacity: 1 !important;
}

.modal .modal-dialog {
    right: -25vw;
    max-height: 100%;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.show .modal-dialog {
    right: 0;
}

.modal-dialog {
    top: 0;
    position: fixed;
    margin: auto;
    width: 25vw !important;
    max-width: 25vw !important;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal-title {
    width: 100%;
}

.modal-title button {
    opacity: 1;
    outline: 0 !important;
    color: var(--prymary);
    font-size: 18px;
    font-weight: bold;
}

.modal-title svg {
    fill: var(--prymary);
}

.modal-body {
    padding: 0 !important;
    margin-top: 15px;
}

.modal .modal-dialog .modal-content {
    height: 100%;
    overflow-y: auto;
    border: none;
    -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    -webkit-box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .5);
    box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .5);
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0 !important;
}

.fade {
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear;
}

.avatarPiccolo {
    flex-grow: 1;
    width: 34px;
}

.timestamp {
    font-size: 11px;
}

.fieldHeavilyEditedWarningText {
    color: orangered;
    font-weight: bold;
    font-size: 14px;
}

.fieldHeavilyEditedWarningIcon {
    fill: orangered !important;
    margin-right: 4px;
}

.sidebarItem {
    min-height: 110px;
    font-size: 14px;
}

.sidebarItem:nth-child(odd) {
    background-color: #F6F7F9 !important;
}
