/* DATA CALENDAR */



.labelTxtInput {
    color: #1D1D1B;
    font-size: 14px;
    width: auto;
    padding: 0 2px;
    margin: 0px;
    padding: 0 2px!important;
}

.required:after {
    content: "*";
    color: var(--adi-red);
    font-weight: 300;
} 


.errorBorder {
    border: 3px solid var(--adi-red) !important;
    border-bottom: 1px solid  var(--adi-red) !important;
    background-color: var(--white);
    font-size: 16px !important;
    color: #1D1D1B !important;
    border-radius: 10px!important;

}

.errorMessage {
    width: 100%;
    margin-top: .25rem;
    font-size: .777rem;
    color: var(--adi-red);
}

.inputDataError {
    border: 3px solid var(--adi-red) !important;
    font-size: 14px !important;
    margin: 0 !important;
    border-radius: 10px !important;
}


.inputData {
    border: 1px solid var(--prymary) !important;
    font-size: 14px;
    color: #1D1D1B;
    text-align: center !important;
    margin-bottom: 0 !important;
    height: 45px !important;
    border-radius: 10px!important;
}

/*mettere tutte le specifiche per IE qui dentro*/
@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .inputData {
        border: 1px solid var(--prymary) !important;
        font-size: 14px;
        color: #1D1D1B;
        text-align: center !important;
        margin-bottom: 0 !important;
        height: 45px !important;
        width: 181px;
        border-radius: 10px!important;
    }
}

.inputData:hover {
    font-size: 14px;
    border: 1px solid #0e3f3b !important;
    color: #1D1D1B;
}

.inputData:active {
    font-size: 14px;
    border: 1px solid #0e3f3b !important;
    color: #1D1D1B;
}

.inputData:focus {
    font-size: 14px;
    border: 1px solid #0e3f3b !important;
    color: #1D1D1B;
}

.inputData:disabled {
    font-size: 14px;
    border: 1px solid var(--prymary) !important;
    color: var(--adi-gray-dark);
    border-radius: 10px!important;
}

.inputDataDisabled {
    font-size: 14px;
    border: 1px solid #d6d6d6 !important;
    border-radius: 10px !important;
    color: var(--adi-gray-dark);
    text-align: center !important;
    margin-bottom: 0 !important;
    height: 45px !important;
    background-color: #e6e9f2;
    opacity: 1;
    cursor: not-allowed !important;
    pointer-events: none;
}

.ddm::before {
    content: "";
    position: absolute;
    top: -11px;
    left: 70px;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top: 1px solid #0e3f3b;
    border-left: 3px solid #0e3f3b;
}

.ddm {
    position: absolute !important;
    will-change: transform;
    top: -40px !important;
    left: 90px !important;
    transform: translate3d(125px, 49px, 0px);
}

.ddmHeader::before {
    content: "";
    position: absolute;
    top: -11px;
    left: 70px;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top: 3px solid #0e3f3b;
    border-left: 3px solid #0e3f3b;
}

.ddmHeader {
    position: absolute !important;
    will-change: transform;
    top: -40px !important;
    left: 90px !important;
    transform: translate3d(125px, 49px, 0px);
}

.dropdown-menu.ddmHeader {
    border: 3px solid #0e3f3b;
    border-radius: 0 !important;
    transform: translate3d(-100px, 100px, 0px) !important;
    padding: 0 !important;
    height: 150px;
}

.dropbtn {
    border-top: 3px solid var(--prymary) !important;
    border-bottom: 3px solid var(--prymary);
    border-left: 3px solid var(--prymary) !important;
    border-right: 3px solid var(--prymary) !important;
    background-color: white;
    color: var(--prymary);
    padding: 16px;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    z-index: 10;
    outline: none !important;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    overflow: auto;
    z-index: 1;
    border: 3px solid var(--prymary) !important;
}

.dropdown-content a {
    color: var(--prymary);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown a:hover {
    background-color: white;
}

.showDrop {
    display: block;
    position: relative;
    bottom: 3px;
}

.opacita {
    opacity: 0.5 !important
}

.placeholder::placeholder {
    opacity: 0.4 !important;
}
