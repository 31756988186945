.customButton {
    margin: 0 !important;
    width: 100%;
}

.rowTableGray {
    background-color: lightgray;
}

.rowTableWhite {
    background-color: white;
}
