.boxScheda {
    border: 1px solid var(--prymary);
    background-color: var(--prymary);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,0.40);
    border-radius: 10px!important;
    color: var(--white);
    min-height: 300px;

}

.boxScheda:after {
    content: "";
    display: block;
}

.roundedIcon {
    border: 1px solid var(--white);
    width: 50px;
    height: 50px;
}

.labelAttivaProfessionista {
    color: var(--white);
    font-weight: 500;
    text-decoration: underline;
    margin-top: 16px
}

.dot {
    width: 8px;
    height: 8px;
    background-image: radial-gradient(circle, var(--white) 10px, transparent 11px);
    border-radius: 50%;
}

.containerStatoUtente {
    border: 1px solid var(--prymary);
    border-radius: 25px;
    width: 100px;
    background-color: var(--white);
    color: var(--black);
    position: relative;
    margin-left: 104px;
    margin-top: 10px;
}

.onlineDot:before {
    content: "\A";
    width: 15px;
    height: 15px;
    background: var(--prymary);
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: -2px;
}

.eliminaProfessionista {
    background: none;
    color: red !important;
    fill: red!important;
}
.eliminaProfessionistaDisabilitato {
    background: none;
    color: dimgrey !important;
    fill: dimgray!important;
    cursor: default;
    pointer-events: none;
}
.eliminaProfessionista:hover {
    text-decoration: underline;
}

.popoverElimina{
    z-index: 8;
}

.schedaSpanLi {
    color: var(--prymary) !important;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    height: 40px;
}

.schedaDropdownItem {
    padding-top: 15px !important;
    padding-bottom: 30px !important;
}

.schedaDropdownItem:active {
    background: #E6ECF2 !important;
}


@media screen and (max-width: 768px) {
    .boxScheda {
        margin-bottom: 10%;
    }
}
