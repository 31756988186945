.container {
    border: 0 solid black;
}

div.linkQuestionario {
    cursor: pointer;
    text-decoration: underline;
    color: var(--prymary);
    font-weight: 700;
}

div.linkQuestionario:hover {
    color: var(--adi-primary-hover);
}

.requiredField {
    font-size: 14px;
    color: var(--adi-red);
    background: #fff;
    font-style: italic;
    font-weight: 500;
}
