.requiredField {
    font-size: 14px;
    color: var(--adi-red);
    background: #fff;
    font-style: italic;
    padding: 0 2px!important;
    font-weight: 300;
}

.bottomPlaceholder {
    opacity: 0.5;
    font-size: 14px;
}
