.btnAnnullaStyle {
    width: 11vw !important;
    min-width: 130px !important;
    height: 2.5rem !important;
    font-size: 14px;
}

.rowTableGray {
    background-color: lightgray;
  }
.rowTableWhite {
    background-color: white;
  }
@media screen and (max-width: 992px) and (min-width: 475px) {

    .btnAnnullaStyle {
        width: 10vw !important;
    }
}



