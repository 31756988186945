.borderStyle {
    border-top: 3px solid var(--prymary) !important;
}

.textSearch{
    color: #1d1d1b;
    font-weight: bold;
}

.sectionScroll {
    overflow-y: auto;
    overflow-x: hidden;
}

.maxHeightContent {
    overflow-y: auto;
    height: 100% !important;
}

.buttonIcons {
    background-color: #F2F2F2 !important;
    border-color: #F2F2F2 !important;
    border-radius: 10px !important;
}

.buttonIconSearch {
    background-color: #F2F2F2 !important;
    border-color: #F2F2F2 !important;
    border-radius: 10px !important;
}

.buttonIconSearch:hover {
    background-color: var(--prymary) !important;
    border-color: #F2F2F2 !important;
    border-radius: 10px !important;
}

.cerca{
    color: var(--prymary) !important;
}
.cerca:hover{
    color: #fff !important;
}

.inputStyle {
    padding: 1.5rem .5rem !important;
    background-color: #F2F2F2 !important;
    border-color: #F2F2F2 !important;
    border-radius: 10px !important;
}

.breadcrumbCentroNotifiche {
    /*margin: 0 0 0.5rem 0!important;*/
    /*padding: 0 !important;*/
    font-size: 0.8rem;
    color: var(--prymary) !important;
    font-weight: 700;
    margin-top: -8px !important;
}

.breadcrumbCentroNotificheElem {
    color: var(--prymary) !important;
    font-weight: 100;
}


.breadcrumbSeparator:before {
    content: '>' !important;
}

.labelFiltri {
    cursor: pointer;
    margin: 0 !important;
    color: black;
}

.labelFiltriSelected {
    text-decoration: underline;
    color: var(--prymary);
}

.labelNotifiche {
    width: 100% !important;
    border: 1px solid #D9DADB;
    border-radius: 10px;
}

.checkboxNotifica {
    width: 15px;
    height: 15px;
}

.checkboxSelezionaTutti {
    padding-left: 0.3vw;
}

.title {
    margin: 0 0 0 16px !important;
    width: 100% !important;
}

.searchBarStyle {
    margin: 0 0 40px 20px !important;
}

.notificaInfoContainer {
    border-radius: 10px;
    background-color: #F2F2F2;
}
