.topToBtm {
    position: relative;
}

.iconStyle {
    position: fixed;
    bottom: 75px;
    z-index: 20;
    background-color: var(--prymary);
    border: 2px solid #fff;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
    text-align: center;
    padding-top: 5px;
    right: 0;
}

.containerTooltip {
    text-align: center !important;
    background: var(--prymary) !important;
    color: var(--white) !important;
    padding: 10px !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}
