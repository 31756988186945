.azTableCellTwo {
    /*border: none !important;*/
    font-size: 14px;
    text-align: center;
    height: 40px;
    padding: 5px;
    min-width: 108px;

}

.testoAzioni {
     font-size: 14px;
     line-height: 20px;
     color: var(--prymary) !important;
     cursor: pointer;
 }

.iconSpaces{
    margin-right: 10px;
}

.popoverStoricoProfessionista {
    width: initial !important;
}

.separator {
    background-color: var(--prymary);
    height: 4px;
    border-radius: 10px;
    margin: 0;
}
