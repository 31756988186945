.layout {
    background-color: var(--white);
    align-items: start;
    border-color: var(--white);
    box-shadow: 0 3px 0 0 rgb(0 0 0 / 16%);
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;

    position: sticky;
    top: 0px;
    z-index: 51;
}

.errorText {
    font-size: 14px;
    color: var(--adi-red);
}

