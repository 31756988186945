.linkQuestionario {
    cursor: pointer;
    text-decoration: underline;
    color: var(--prymary);
    font-weight: 700;
}

.linkQuestionario:hover {
    color: var(--adi-primary-hover);
}
