.backgroundMode {
    z-index: -1;
    opacity: 20%;
    pointer-events: none;
    background-color: #F6F7F9 !important;
}

.apriAzione {
    color: var(--prymary) !important;
    text-decoration: none !important;
    font-weight: bold !important;
    font-size: 0.8rem !important;
}

.apriAzione:hover {
    color: var(--adi-primary-hover) !important;
    text-decoration: underline !important;
}

.dropdownAzioni {
    position: static !important;
}

.dropdownMenu, .dropdownMenu:before {
    position: absolute !important;
    content: none !important;
    left: -10px !important;
    top: 0vw !important;
}

.dropdownToggle {
    border: none !important;
    background-color: transparent !important;
}

.dropdownToggle:focus {
    background-color: #bfd7e0 !important;
    border: none !important;
}

.dropdownToggle::after {
    content: none !important;
}

.backgroundMode {
    z-index: -1;
    opacity: 20%;
    pointer-events: none;
    background-color: #F6F7F9 !important;
}
