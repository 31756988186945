.backgroundMode {
    z-index: -1;
    opacity: 20%;
    pointer-events: none;
    background-color: #F6F7F9 !important;
}

.title {
    color: var(--prymary);
    font-size: 18px;
    font-weight: 700;
}

.containerPopoverTeamIcon {
    text-align: center !important;
}

.teamIconConfermato {
    margin-left: 5% !important;
    padding-right: 20px !important;
}

.teamIconNonConfermato {
    margin-left: 10% !important;
}

.filtriRapidi {
    background-color: var(--adi-gray-400);
}

.homeContainer {
     min-height: calc(100vh - 9.7rem);
}
