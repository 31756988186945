.radioButtonContainerRow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}

@media screen and (max-width: 675px) {
  .radioButtonContainerRow {
    display: inline;
  }
}

.radioButtonContainerCol {
  min-width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fontRadioButton {
  font-size: 16px !important;
  color: #1D1D1B !important;
}
