.modal {
    overflow: hidden !important;
}

/* contenuto  */
.modal-helper.modal .modal-dialog .modal-content {
    border-radius: 22px;
    font-size: 14px !important;
    min-height: 21vw !important;
}

/* Header */
.modal-helper.modal .modal-dialog .modal-content .modal-header { 
    font-weight: bold;
    padding: 0.8vw !important;
    font-size: 14px !important;
}

.modal-helper.modal .modal-dialog .modal-content .modal-header .modal-title { 
    font-size: 14px !important;
}

/* GREEN MODAL */
.modal-helper.modal .modal-dialog .modal-content .backgroundGreen { 
    background: var(--prymary);
    color: white;
}

.modal-helper.modal .modal-dialog .modal-content .modalGreen {
    border: 0.1vw solid var(--prymary) !important;
    background-color:  var(--prymary) !important;
    color: white;
}
/* GREEN MODAL */

/* ORANGE MODAL */
.modal-helper.modal .modal-dialog .modal-content .backgroundOrange { 
    background-color: var(--adi-orange) !important;
    color: white;
}

/* .modal .modal-dialog .modal-content .btnOrangeOutline { 
    background-color: var(--adi-orange) !important;
    color: var(--white) !important;
} */

/* .modal .modal-dialog .modal-content .btnWhiteOutlineBorderOrange { 
    background-color: var(--white) !important;
    color: var(--adi-orange) !important;
} */

/* ORANGE MODAL */


.modal-backdrop.show {
    opacity: 0.8 !important;
}

body.modal-open {
    overflow: hidden !important
}

.modal-helper.modal .modal-dialog .modal-content .modal-body {
    padding: 24px 24px 5px!important;
    font-size: 14px!important;
}

.modal-helper.modal .modal-dialog .modal-content .modal-body p {
    font-size: 14px!important;
    font-weight: 600;
}

.modal-helper.modal .modal-dialog .modal-content .btn.btn-adi{
  min-width: 200px;
}