.checkBoxContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
}

.fontCheckBox {
    font-size: 14px;
    color: #1D1D1B !important;
}

.removeMargin {
    min-height: 2em;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.removeLabelMargin {
    margin-bottom: 0 !important;
}

.form-check [type=checkbox] + label {
    font-size: 14px;
}
