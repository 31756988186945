.container {
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    min-height: calc(100vh - 20rem);
}

.info {
    text-transform: uppercase;
    color: var(--prymary);
    font-weight: 700;
}

.separator {
    background-color: var(--prymary);
    height: 4px;
    border-radius: 10px;
    margin: 0;
}
