.navbarCustomStyle {
    background: var(--adi-gray-300)!important;
    width: 100px;
}

.roundedIcon {
    border: 1px solid #FFFFFF;
    width: 50px;
    height: 50px;
    background-color: transparent;
}

.listFirstLevelAccordions {
    width: 90%;
    font-size: 14px;
    margin-left: 90px;
    height: 70vh;
    position: relative;
}

.listFirstLevelAccordions > li {
    color: var(--white) !important;
    background-color: transparent !important;
    border: none;
    padding: 0.2rem 0.0rem;
}

.listFirstLevelAccordions > li:hover {
    color: var(--black) !important;
    font-weight: bold;
    background-color: #cccccc !important;
}

.listFirstLevelAccordions::-webkit-scrollbar {
    width: 10px;
}

.listFirstLevelAccordions::-webkit-scrollbar-track {
    color: var(--white) !important;
    background-color: var(--adi-gray-dark) !important;
}

.listFirstLevelAccordions::-webkit-scrollbar-thumb {
    color: var(--white) !important;
    background-color: var(--adi-gray-dark) !important;
}

.listSecondLevelAccordions {
    font-size: 14px;
}

.listSecondLevelAccordions > li {
    color: var(--white) !important;
    border: none;
    padding: 0.2rem 0.0rem;
    background-color: var(--adi-gray-700) !important;
    padding-left: 3rem !important;
}

.listSecondLevelAccordions > li:hover {
    color: var(--black) !important;
    font-weight: bold;
    background-color: #cccccc !important;
}

.activeArrowIcon {
    fill: var(--white);
}

/*.activeArrowIcon:hover {
    fill: var(--white);
}*/

.contattiUtili{
    margin-left: -40px;
    cursor: pointer;
}

.contattiUtili:hover{
    color: var(--black) !important;
    font-weight: bold;
    background-color: #cccccc !important;
    width: 44vh;
}

.disabledArrowIcon {
    fill: var(--black);
    opacity: 0.7;
}

.customBadge {
    width: 24px;
    height: 24px;
    color: var(--prymary) !important;
    background-color: #ccc !important;
    display: inline !important;
    border-radius: 16px;
}

.scroll {
    overflow: auto;
    overflow-x: hidden;
}
.scroll::-webkit-scrollbar {
    width: 12px;
}

.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.menuPosition{
    width: 95.4%;
    top: 0;
    position: fixed;
    z-index: 100;
    margin-left: -1px !important;
    min-height: 100%;
}

.bgInfoIcon{
    background-color: #FFFFFF;
}

@media (max-width: 992px) {
    .menuPosition{
        width: 95.4%;
        top: 0;
        position: fixed;
        z-index: 100;
        margin-left: -17px !important;
    }
}
