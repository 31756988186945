:root {
    --white: #ffffff;
    --prymary: #176A65;
    --adi-red: #D83D3D;
    --adi-gray-dark: #333333;
    --adi-gray: #6c6c6c;
    --yellow: #986f01;
    --adi-yellow-100: #F4B740;
    --success: var(--prymary);
    --adi-primary-hover: #0e3f3b;
    --adi-gray-100: #E0E0E0;
    --adi-gray-200: #BDBDBD;
    --adi-gray-300: #333333;
    --adi-gray-400: #F2F2F2;
    --adi-gray-500: #828282;
    --adi-gray-600: #E5E5E5;
    --adi-gray-700: #4F4F4F;
    --black: black;
    /*--adi-orange: #FFB17F;*/
    --adi-orange: #FF6400;
}
