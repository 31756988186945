.stickyBar {
    position: sticky;
    top: 36px;
    z-index: 51;
    border: 1px solid rgb(220 220 220);
    background-color: var(--white);
}

.circle {
    border: 1px solid grey;
    width: 40px;
    height: 40px;
    display: table-cell;
    overflow: hidden;
    vertical-align: middle;
    text-align: center;
}
