.App-logo {
  height: 40vmin;
}

.iconStyle{
    border: 2px solid #fff;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    color: #fff;
    cursor: pointer;
    cursor: pointer;
    text-align: center;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.link {
  color: var(--prymary) !important;
  cursor: pointer;
  font-size: 14px !important;
  line-height: 60px !important;
}

.link:hover {
  color: var(--adi-primary-hover) !important;
  text-decoration: underline !important;
}

a:hover {
  color: var(--adi-primary-hover)!important;
}

.generalLayout {
  margin: auto;
  width: 95%;
  align-content: stretch;
  justify-content: stretch;
  flex-grow: 3;
  min-width: 555px;
  font-size: 14px!important;
  color: #1D1D1B;
  position: relative;
}

.dropdownItemCustom {
  height: 40px;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 30px !important;
}

.dropdownItemCustom:hover {
  background-color: transparent !important;
  text-decoration: none !important;
  text-decoration-color: var(--prymary) !important;
}

.spanCustom {
  font-size: 14px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

.footer {
  height: 60px;
  background-color: var(--prymary);
  color: var(--white);
  font-size: 13px;
}

.listItem {
  color: var(--prymary);
  text-decoration: none;
  font-weight: bold;
  font-size: .889em;
  line-height: 2.3em;
  padding-right: 40px !important;
  padding-left: 40px !important;
  display: block;
  white-space: nowrap;
}

.listItem-link:hover {
  text-decoration: none !important;
  color: #1b7ea9 !important;
}

.actionIcon {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 5.2px;
}

.actionIcon:hover {
  background: var(--adi-primary-hover);
  text-decoration: none !important;
}

.caption {
  display: block;
  font-size: 14px;
  color: var(--white);
}

.caption:hover {
  color: black;
  text-decoration: none !important;
}

.nonLette {
  height: 23px;
  width: 23px;
  background-color: var(--adi-red);
  border-radius: 50%;
  font-size: 11px;
  color: white;
  position: absolute;
  text-align: center;
  padding-top: 2px;
}

.dropdown-menu:before {
  border-top: 1px solid var(--prymary) !important;
  border-left: 1px solid var(--prymary) !important;
  top: -10px !important;
  border-radius: 4px 0 0 0 !important;
}

.dropdown-menu {
  border: 1px solid var(--prymary) !important;
}

.linkNotifica, .linkNotifica:hover {
  text-decoration: none !important;
  color: black !important;
}

.apriCentroNotifiche,
.apriCentroNotifiche:hover {
  color: var(--prymary) !important;
  text-decoration: none !important;
  font-size: 14px !important;
  font-weight: bold !important;
  outline: none !important;
}

.dropdown-divider {
  border-top: 1px solid var(--prymary) !important;
}

.dropdown-notifiche {
  width: 24rem !important;
}

.background-mode {
  z-index: -1;
  opacity: 20%;
  pointer-events: none;
  background-color: #F6F7F9 !important;
}

#idDropdownMenuNotifiche {
  will-change: unset !important;
  transform: none !important;
  top: 50px !important;
  left: -9rem !important;
  border: 2px solid var(--adi-primary-hover) !important;
  border-radius: 10px;
  z-index: 100;
}

#idDropdownMenuNotifiche.dropdown-menu:before {
  left: 190px;
}

#idDropdownNotifiche.dropdown.show > .dropdown-menu[x-placement=bottom-start] {
  animation: none !important;
}

.dropdownMenuDivider {
  border-top: 2px solid #D9DADA !important;
}

button.dropdown-item:active {
  background-color: #E6ECF2 !important;
  outline: none !important;
}

#idDropdownMenuUtente{
  will-change: unset !important;
  transform: none !important;
  left: -145px!important;
  border: 2px solid var(--adi-primary-hover) !important;
  border-radius: 10px;
  top: 36px !important;
  z-index: 100;
}

.iconeHeader{
  width: 34px;
  height: 34px;
  margin-bottom: 2px;
}


#idDropdownMenuUtente.dropdown-menu:before {
  left: 171px;
}


@media screen and (max-width: 1199px) {
  #idDropdownMenuUtente{
    top: 50px!important;
    left: -32px!important;
  }

  #idDropdownMenuUtente.dropdown-menu:before {
    left: 53px;
  }

  #idDropdownMenuNotifiche {
    top: 50px!important;
    left: 0 !important;
  }

  #idDropdownMenuNotifiche.dropdown-menu:before {
    left: 25px;
  }
  

}

.bg-blur{
  filter: blur(1.5rem);
}






