.customContainer {
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}

.accordionDettaglio {
    margin-bottom: 16px;
}

.accordionDettaglio:after {
    content: "";
    display: block;
}
