.highlight {
    background-color: yellow !important;
}

.draggableContainer {
    position: sticky;
    top: 0;
    width: 40%;
    height: 0;
    z-index: 99;
}

.containerPopoverSearchBar {
    text-align: center !important;
    padding-top: 5px !important;
    margin-left: 15% !important;
}

.containerPopoverSearchBar:after {
    content: "  ";
    white-space: pre;
}
