.layout {
  background-color: #d1e5ee;
  align-items: start;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 1%;
}

.filterRow {
  display: flex;
  align-items: center;
  margin-left: -16px !important;
}

.btnAnnullaStyle {
  width: 11vw !important;
  min-width: 130px !important;
  height: 2.5rem !important;
  font-size: 14px;
}

/* 992px è lo schermo lg*/
@media screen and (max-width: 992px) and (min-width: 475px) {
  .btnAnnullaStyle {
    width: 25vw !important;
  }
}

.annullaBtn {
  max-height: 45px;
  margin-left: 1%;
  width: auto;
  font-size: 14px;
}

.addSpace {
  margin-left: 25px;
  height: 0;
}
.modal-backdrop.show {
  opacity: 0.8;
}

@media screen and (min-width: 992px) and (max-width: 1300px) {
  .addSpace {
    margin-left: 65px;
  }
}

.bodyModal {
  padding-bottom: 5.6vw !important;
}