.transition{
    transition: height 2s;
}

.header {
    background-color: var(--white);
}

.btnheader {
    color: var(--white) !important;
}

.noBorder {
    border: none !important;
}

.border {
    border: 0.5px solid var(--adi-gray-dark);
    min-height: 180px;
}
