.collapseHeader {
    border: 1px solid #e3e4e6;
    border-radius: 10px;
    box-shadow: 0px 3px 5px #dedede;
    border-top: 1px solid #e3e4e6 !important;
}

.requiredFieldsMissing {
    color: var(--adi-red);
    font-weight: 500;
    margin: 0;
    margin-right: 24px;
}

.title {
    color: var(--prymary);
    text-decoration: underline;
    font-size: 14px;
    font-weight: 700;
}

.aggiungiInformazioneAggiuntiva {
    color: var(--prymary);
    text-decoration: underline;
    font-size: 14px;
    font-weight: 700;
}
