.container {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 13.8rem);
}

.requiredText {
    font-size: 14px;
    color: var(--adi-red);
}

.infoSticky {
    position: sticky;
    top: -25px;
    padding-top: 1px;
    z-index: 5;
}


.aggiungiSetting {
    color: var(--prymary);
    cursor: pointer;
}

.btnDisabled {
    opacity: 0.5;
}
