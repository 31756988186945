.previewNotifica {
    border-radius: 10px;
    cursor: pointer;
}

.previewNotifica:hover {
    background-color: #eaf3f7;
}

.previewNotificaLetta {
    background-color: #F2F2F2 !important;
}

.previewNotificaLetta:hover {
    background-color: #EAF3F7 !important;
}

.previewNotificaNonLetta {
    font-weight: bold;
}

.titoloPreviewNotificaCentro {
    color: var(--prymary);
    text-decoration: underline;
    white-space: normal !important;
}

.titoloPreviewNotifica {
    color: var(--prymary);
    text-decoration: underline;
}

.notificaNonLetta {
    font-weight: bold;
}

.selectedPreview {
    background-color: var(--adi-gray-100) !important;
}

.ellipsisText {
    white-space: nowrap;
    overflow-x: hidden !important;
    text-overflow: ellipsis !important; 
}

.capitalizeLabel {
    text-transform: capitalize;
}
