.modalBtnStyle {
    width: 9vw !important;
    height: 2vw !important;
    font-weight: bold;
}

.modalBtnConfermaAnnullamento {
    width: 15vw !important;
}

.modalBtnStyle:active, .modalBtnStyle:hover, .modalBtnStyle:focus {
    color: white !important;
}

.modalBtnStyle:disabled {
    color: #dddddd !important;
}

.modalOrange {
    border: 0.1vw solid var(--adi-orange) !important;
    color: var(--adi-orange) !important;
}

.modalGreen {
    border: 0.1vw solid var(--prymary) !important;
    color: var(--prymary) !important;
}

.modalBlue {
    color: var(--prymary) !important;
}

.modalOrange:active, .modalOrange:hover, .modalOrange:focus {
    background-color: var(--adi-orange) !important;
}

.modalGreen:active, .modalGreen:hover, .modalGreen:focus {
    background-color: var(--prymary) !important;
}

.modalHeader {
    color: white;
    font-weight: bold;
    padding: 0.8vw !important;
    font-size: 14px !important;
}

.modalBody {
    vertical-align: middle !important;
    text-align: center !important;
    white-space: pre-wrap;
}

.modalContent {
    height: 24vw !important;
}

.fontSizeText {
    font-size: 14px !important;
}

.backgroundOrange {
    background-color: var(--adi-orange) !important;
}

.backgroundGreen {
    background-color: var(--prymary) !important;
}

.backgroundBlue {
    background-color: var(--prymary) !important;
}

.inputLabel {
    color: black;
}

.infoInput {
    font-size: 0.7vw !important;
}

.campoObbligatorio {
    color: var(--adi-red)
}

.closeAnnullaRichiesta {
    position: absolute;
    right: 10px;
    padding-right: 1vw;
    cursor: pointer;
}

.modalDialog {
    max-width: 30vw !important;
    height: 100% !important;
}

.modalFooter {
    padding-bottom: 1.6vw !important;
}