.generalLayout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid var(--prymary);
    max-height: 45px; /* come le input text*/
    background-color: var(--white);
    border-radius: 10px;
}

.generalLayout:hover {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 45px; /* come le input text*/
    background-color: var(--white);
}

.generalLayout:active {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 45px; /* come le input text*/
    background-color: var(--white);
}

.generalLayout:focus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 45px; /* come le input text*/
    background-color: var(--white);
}

.generalLayout:disabled {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #D6D6D6;
    max-height: 45px; /* come le input text*/
    background-color: #D6D6D6;
}

@media screen and (max-width: 762px) {
    .generalLayout {
        margin: 0 !important;
    }

    .spinnersLayout {
        position: relative !important;
        margin: 0 !important;
        border-top: none;
    }
}

.timeLayout {
    display: flex;
    max-width: 60%;
}

.separatorLayout {
    height: 35px !important;
    margin-top: 0.2px;
    border: transparent !important;
    background-color: white;
    cursor: default;
    max-height: 42px;
    width: 5px !important;
    padding: 0 !important;
}

.inputTime {
    border-top: transparent !important;
    max-height: 42px;
    width: 80% !important;
}

.hoursLayout {
    border-top: transparent !important;
    max-height: 42px;
    width: 40% !important;
}

.hoursLayout:hover {
    color: #886300 !important;
}

.hoursLayout:active {
    color: #6f5100 !important;
}

.hoursLayout:focus {
    color: #886300 !important;
}

.hoursLayout:disabled {
    color: var(--adi-gray-dark) !important;
}

.minuteLayout {
    border-top: transparent !important;
    max-height: 42px;
    width: 40% !important;
}

.minuteLayout:hover {
    color: #886300 !important;
}

.minuteLayout:active {
    color: #6f5100 !important;
}

.minuteLayout:focus {
    color: #886300 !important;
}

.minuteLayout:disabled {
    color: var(--adi-gray-dark) !important;
}

.iconTimeLayout {
    cursor: pointer;
}

.spinnersLayout {
    border: 1px solid var(--prymary);
    /*border-top: none;*/
    position: absolute;
    z-index: 1;
    width: calc(100% - 12px);
    background: #fff;
    bottom: -40px;
    border-radius: 10px;
}

.hourSpinner {
    border-right: white !important;
    border-bottom: white !important;
}

.hourSpinner:hover {
    color: #886300 !important;
}

.hourSpinner:active {
    color: #6f5100 !important;
}

.hourSpinner:focus {
    color: #886300 !important;
}

.hourSpinner:disabled {
    color: var(--adi-gray-dark) !important;
}

.minuteSpinner {
    border-left: white !important;
    border-bottom: white !important;
}

.minuteSpinner:hover {
    color: #886300 !important;
}

.minuteSpinner:active {
    color: #6f5100 !important;
}

.minuteSpinner:focus {
    color: #886300 !important;
}

.minuteSpinner:disabled {
    color: var(--adi-gray-dark) !important;
}
