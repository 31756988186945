.breadcrumbBorder {
    border-bottom: 1px solid rgb(200, 200, 200);
}

.customContainer {
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}

.customModalBody {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.customModalContainer {
    width: 60vh !important;
    overflow: hidden !important;
}
