@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Exo+2);
@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css);
:root {
    --white: #ffffff;
    --prymary: #176A65;
    --adi-red: #D83D3D;
    --adi-gray-dark: #333333;
    --adi-gray: #6c6c6c;
    --yellow: #986f01;
    --adi-yellow-100: #F4B740;
    --success: var(--prymary);
    --adi-primary-hover: #0e3f3b;
    --adi-gray-100: #E0E0E0;
    --adi-gray-200: #BDBDBD;
    --adi-gray-300: #333333;
    --adi-gray-400: #F2F2F2;
    --adi-gray-500: #828282;
    --adi-gray-600: #E5E5E5;
    --adi-gray-700: #4F4F4F;
    --black: black;
    /*--adi-orange: #FFB17F;*/
    --adi-orange: #FF6400;
}

.modal {
    overflow: hidden !important;
}

/* contenuto  */
.modal-helper.modal .modal-dialog .modal-content {
    border-radius: 22px;
    font-size: 14px !important;
    min-height: 21vw !important;
}

/* Header */
.modal-helper.modal .modal-dialog .modal-content .modal-header { 
    font-weight: bold;
    padding: 0.8vw !important;
    font-size: 14px !important;
}

.modal-helper.modal .modal-dialog .modal-content .modal-header .modal-title { 
    font-size: 14px !important;
}

/* GREEN MODAL */
.modal-helper.modal .modal-dialog .modal-content .backgroundGreen { 
    background: var(--prymary);
    color: white;
}

.modal-helper.modal .modal-dialog .modal-content .modalGreen {
    border: 0.1vw solid var(--prymary) !important;
    background-color:  var(--prymary) !important;
    color: white;
}
/* GREEN MODAL */

/* ORANGE MODAL */
.modal-helper.modal .modal-dialog .modal-content .backgroundOrange { 
    background-color: var(--adi-orange) !important;
    color: white;
}

/* .modal .modal-dialog .modal-content .btnOrangeOutline { 
    background-color: var(--adi-orange) !important;
    color: var(--white) !important;
} */

/* .modal .modal-dialog .modal-content .btnWhiteOutlineBorderOrange { 
    background-color: var(--white) !important;
    color: var(--adi-orange) !important;
} */

/* ORANGE MODAL */


.modal-backdrop.show {
    opacity: 0.8 !important;
}

body.modal-open {
    overflow: hidden !important
}

.modal-helper.modal .modal-dialog .modal-content .modal-body {
    padding: 24px 24px 5px!important;
    font-size: 14px!important;
}

.modal-helper.modal .modal-dialog .modal-content .modal-body p {
    font-size: 14px!important;
    font-weight: 600;
}

.modal-helper.modal .modal-dialog .modal-content .btn.btn-adi{
  min-width: 200px;
}
label {
    color: #1D1D1B;
}

body {
  margin: 0;
  padding: 0;
  font-family: Tahoma!important;
}

::-webkit-input-placeholder {
    color: var(--adi-gray-300) !important;
    opacity: 0.9 !important;
  }

::placeholder {
    color: var(--adi-gray-300) !important;
    opacity: 0.9 !important;
  }
  

input[type=date], input[type=datetime-local], input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], textarea,
.form-check [type=checkbox]+label, .form-check [type=radio]+label
{
    font-size: 14px!important;
}

.f-weight-700 {
    font-weight: 700;
}

.f-weight-600 {
    font-weight: 600;
}

.f-weight-500 {
    font-weight: 500;
}

.icon-green {
    fill: var(--prymary);
}

.table thead th,
.table td {
    padding-right: 0 !important;
    padding-left: 0 !important;
    color: #19191a!important;    
}

.stato-valutazione-table > tbody > tr > td:first-child,
.stato-valutazione-table > thead > tr > th:first-child 
{
    padding-left: 10px!important;

}

.stato-valutazione-table > thead > tr > th {
    color: black!important;
}

.stato-valutazione-table > tbody > tr > td
{
    border:none;
    border-radius: 5px!important;
    font-size: 14px!important;
    color: black!important;
    font-weight: 400!important;
}

.stato-valutazione-table {
    overflow-x: hidden!important;
    margin-bottom: 2px!important;
    color: var(--black)!important;
}

.storico-richieste-table > thead > tr > th,
.storico-richieste-table > tbody > tr > td {
    padding: 1em !important;
}

.firme-table > tbody > tr > td {
    padding: 0 !important;
}

.firme-table > thead th {
    padding-right: 1em !important;
}

.progress-spinner.progress-spinner-active:not(.progress-spinner-double) {
    border-color: var(--prymary) var(--prymary) #d4e9ff!important;
}

.btn-link {
    color: var(--prymary)!important;
    font-size: 14px!important;
}

.font {
    color: var(--adi-gray-dark);
    font-size: 14px;
    line-height: 20px;
}

strong, b {
    font-size: 14px;
    line-height: 20px;
}

.styleContainer {
    min-height: 90vh;
}

/* DATE PICKER CSS */
.it-date-datepicker.form-control {
    min-width: 0em !important;
}

.it-datepicker-wrapper .datepicker-calendar ::after {
    font-size: 0px !important;
    line-height: 0% !important;
    width: 0px !important;
    border-bottom: 20px solid #77c !important;
    border-left: 10px solid #f6f6f6 !important;
    border-right: 10px solid #f6f6f6 !important;
}


.it-datepicker-wrapper .datepicker-calendar {
    top: unset !important;
    left: 0 !important;
    text-align: center;
    min-width: 20em !important;
    border-top: 5px solid var(--prymary) !important;
}

.datepicker-calendar.default table.datepicker-grid td.selectable.focus span,
.datepicker-calendar.default table.datepicker-grid td.curDay span, 
.datepicker-calendar.default table.datepicker-grid td.curMonth span, 
.datepicker-calendar.default table.datepicker-grid td.curYear span {
    background: #176A65!important;
    color: white;
}

.datepicker-calendar.default table.datepicker-grid td.selectable.focus span {
    opacity: 0.5;
}

.datepicker-month-wrap {
    min-width: 15em !important;
}

.datepicker-grid {
    min-width: 15em !important;
}

.it-datepicker-wrapper .input-group:after {
    display: none !important;
}

.it-datepicker-wrapper .input-group .it-date-datepicker {
    font-size: 14px !important;
    padding-right: 0px !important;
    padding-bottom: 10px !important;
}

.it-datepicker-wrapper .input-group .datepicker-button {
    margin-right: 5px !important;
}

.it-datepicker-wrapper .input-group .datepicker-button:hover .icon {
    fill: var(--adi-primary-hover)!important;
}

/*END DATE PICKER CSS*/

/* TEXT CSS */
input[type=text], input[type=number], textarea {
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: normal !important;
}

input[type=text]:hover, input[type=number]:hover, textarea:hover, input.it-date-datepicker:hover {
    color: #886300 !important;
}

input[type=text]:active, input[type=number]:active, textarea:active, input.it-date-datepicker:active {
    color: #6f5100 !important;
}

input[type=text]:focus, input[type=number]:focus, textarea:focus, input.it-date-datepicker:focus {
    color: #886300 !important;
}

input[type=text]:disabled, input[type=number]:disabled, textarea:disabled, input.it-date-datepicker:disabled {
    color: var(--adi-gray-dark) !important;
    background-color: var(--adi-gray-600);
}

/* FINE TEXT CSS */

input[type=number]::-webkit-inner-spin-button {
    opacity: 1 !important;
}

/*CSS TIMEPICKER*/

#idHour1 {
    height: 35px;
    margin-top: 1px;
    border: transparent;
    font-size: 14px !important;
    width: 18px !important;
    padding: 0px !important;
    color: var(--prymary);
}

#idHour1::-webkit-input-placeholder { /* WebKit browsers */
    color:var(--prymary);
}

#idMinute1 {
    height: 35px;
    margin-top: 1px;
    border: transparent;
    font-size: 14px !important;
    width: 25px !important;
    padding: 0px !important;
    text-align: initial !important;
    color: var(--prymary);
}

#idMinute1::-webkit-input-placeholder { /* WebKit browsers */
    color: var(--prymary);
}

#idHour {
    height: 35px;
    margin-bottom: 2px;
    margin-left: 0px;
    border: none;
    font-size: 14px !important;
    width: 45px !important;
    padding: 0px !important;
    color: var(--prymary);
    background: transparent;
}

#idMinute {
    height: 35px;
    margin-bottom: 2px;
    border: none;
    font-size: 14px !important;
    width: 45px !important;
    padding: 0px !important;
    color: var(--prymary);
}

@media screen and (max-width: 767px) and (min-width: 500px) {
    #idHour {
        margin-top: 1.5px !important;
    }

    #idMinute {
        margin-top: 1.5px !important;
    }
}

@media screen and (max-width: 500px) and (min-width: 300px) {
    #idHour {
        margin-top: 2px !important;
    }

    #idMinute {
        margin-top: 2px !important;
    }
}

.timepicker-icon {
    fill:var(--prymary) !important;
}

.timepicker-icon:hover {
    fill: var(--prymary) !important;
}

/* FINE CSS TIMEPICKER*/

@media (min-width: 300px) {
    .navbar .dropdown-menu.show {
        -webkit-animation: .3s forwards dropdownFadeIn !important;
                animation: .3s forwards dropdownFadeIn !important;
        top: 53px !important;
    }

    .navbar .dropdown-menu {
        position: absolute !important;
        left: -10px !important;
        z-index: 1000;
        float: left;
        min-width: 10rem;
        padding: .5rem 0 0;
        margin: .125rem 0 0 !important;
        font-size: 1rem;
        color: #19191A;
        text-align: left;
        list-style: none;
        background-color: var(--white);
        background-clip: padding-box;
        border: 0 solid transparent;
        border-radius: 0 0 4px 4px;
        box-shadow: 0 20px 30px 5px rgba(0, 0, 0, .05);
    }

    .navbar .dropdown-toggle {
        display: block;
        justify-content: inherit;
    }
}

#dropdownMenuButton {
    min-width: 9rem;
}

@media screen and (max-width: 1199px) {
    #popoverInfoNavBar {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    #popoverInfoNavBar {
        background: var(--prymary) !important;
        color: var(--white) !important;
        padding: 5px 20px !important;
        font-size: 12px !important;
        border-radius: 10px !important;
        left: 1703px!important;
    }
}

@media screen and (max-width: 575px) and (min-width: 300px), (max-width: 1000px) {
    #dropdownMenuButton {
        height: 58px;
    }
}

#logoutMenu.show {
    width: 12.6rem;
    top: 10px !important;
    border: 3px solid var(--adi-primary-hover);
    margin-left: -70px !important;
}

@media screen and (max-height: 240px) {
    #logoutMenu.show {
        /* transform: translateY(90px) !important; */
        margin-top: 87px !important;
    }
}

/* freccettina del menu logout */
#logoutMenu.show::before {
    top: -11px !important;
    border-top: 3px solid var(--adi-primary-hover);
    border-left: 3px solid var(--adi-primary-hover);
    left: 90px !important;
}

.form-check [type=checkbox] + label {
    font-size: 14px;
    color: var(--adi-gray-dark);
}

.form-check [type=radio] + label {
    font-size: 14px;
    color: var(--adi-gray-dark);
}

.form-check [type=radio] + label::after,
.form-check [type=radio] + label::before,
.form-check [type=checkbox] + label::after {
    border: 2px solid var(--prymary) !important;
}

.form-check [type=radio]:checked + label::after,
.form-check [type=checkbox]:checked + label::after {
    background-color: var(--prymary) !important;
}

.required:after {
    content: "*";
    color: var(--adi-red);
}

.react-tiny-popover-container {
    left: calc(100% - 5px) !important;
}

.popover-helper {
    width: calc(25%) !important;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    z-index: 99;
}

#popover-helper {
    margin-top: 6px;
    background: var(--prymary) !important;
    color: var(--white) !important;
    padding: 10px !important;
    font-size: 16px !important;
    border-radius: 5px !important;
    z-index: 99;
}

#popover-ellipsis {
    background: var(--prymary) !important;
    color: var(--white) !important;
    padding: 10px !important;
    font-size: 16px !important;
    text-align: center;
    border-radius: 5px !important;
    margin-top: 50px !important;
}

#popover-info {
    background: var(--prymary) !important;
    color: var(--white) !important;
    padding: 10px;
    font-size: 14px !important;
    border-radius: 5px !important;
    width: 85.4882%;
}

/* TITOLO CSS*/

h1 {
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 36px !important;
    color: var(--prymary);
}

/* END TITOLO CSS */


/* LIVELLI SOTTOTITOLI CSS */

h2 {
    font-size: 20px !important;
    line-height: 32px !important;
    font-weight: 700 !important;
    color: #1D1D1B !important;
}

h3 {
    font-size: 14px !important;
    font-weight: 700 !important;
    color: #1D1D1B !important;
}

/* END LIVELLI SOTTOTITOLI CSS */


/* LINK CSS */

a:link {
    font-size: 14px;
    line-height: 20px;
    color: var(--prymary);
}

a:hover {
    font-size: 14px;
    line-height: 20px;
    color: var(--adi-primary-hover);
}

a:active {
    font-size: 14px;
    line-height: 20px;
    color: #084662;
}

a:focus {
    font-size: 14px;
    line-height: 20px;
    color: var(--adi-primary-hover);
}

a:visited {
    font-size: 14px;
    line-height: 20px;
    color: var(--prymary);
}

/* END LINK CSS */

.progress-spinner.size-sm {
    width: 25px !important;
    height: 25px !important;
}

/*stili radio e checkbox disabilitate*/
.form-check [type=checkbox]:disabled + label::after {
    background-color: var(--adi-gray-500) !important;
    border-color: #C3C5CB !important;
    opacity: 0.8;
}

.form-check [type=radio]:disabled + label::after {
    border-color: #C3C5CB !important;
    background-color: var(--adi-gray-500) !important;
}

.form-check [type=radio]:disabled + label::before {
    border-color: #C3C5CB !important;
}

.collapse-div.collapse-left-icon .collapse-header [data-toggle=collapse]:before {
    color: var(--prymary);
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%)!important;
            transform: translateY(-50%)!important;
    margin: 0 1rem 0 0;
}

.bottoniLayout {
    background-color: var(--adi-gray-100);
    box-shadow: 0 -2px 0 rgb(220 220 220);
    /* bottom: 0; */
    /* position: sticky;
    bottom: 0;
    box-shadow: 0 -2px 0 rgb(220 220 220);
    z-index: 5;
    background-color: var(--adi-gray-100);
    padding: 0 15px; */
}

.collapse-body .collapse-header button[aria-expanded=true]:before {
    height: 21px !important;
    width: 27px !important;
}

.collapse-header [data-toggle=collapse] {
    padding: 0px!important;
}

.list-group-item.disabled {
    color: #656566 !important;
}

.card:after {
    all: unset !important;
}

.list-group-item.disabled {
    color: var(--black) !important;
    opacity: 0.7;
}

.customScrollbarStyle::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0.5);
}

.customScrollbarStyle::-webkit-scrollbar {
    width: 14px;
}

.customScrollbarStyle::-webkit-scrollbar-track {
    background: #F6F6F6;
}

.customScrollbarStyle::-webkit-scrollbar-thumb {
    background-color: #C5C5C5;
    border-radius: 20px;
    border: 3px solid #F6F6F6;
}

.App-logo {
  height: 40vmin;
}

.iconStyle{
    border: 2px solid #fff;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    color: #fff;
    cursor: pointer;
    cursor: pointer;
    text-align: center;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.link {
  color: var(--prymary) !important;
  cursor: pointer;
  font-size: 14px !important;
  line-height: 60px !important;
}

.link:hover {
  color: var(--adi-primary-hover) !important;
  text-decoration: underline !important;
}

a:hover {
  color: var(--adi-primary-hover)!important;
}

.generalLayout {
  margin: auto;
  width: 95%;
  align-content: stretch;
  justify-content: stretch;
  flex-grow: 3;
  min-width: 555px;
  font-size: 14px!important;
  color: #1D1D1B;
  position: relative;
}

.dropdownItemCustom {
  height: 40px;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 30px !important;
}

.dropdownItemCustom:hover {
  background-color: transparent !important;
  text-decoration: none !important;
  -webkit-text-decoration-color: var(--prymary) !important;
          text-decoration-color: var(--prymary) !important;
}

.spanCustom {
  font-size: 14px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

.footer {
  height: 60px;
  background-color: var(--prymary);
  color: var(--white);
  font-size: 13px;
}

.listItem {
  color: var(--prymary);
  text-decoration: none;
  font-weight: bold;
  font-size: .889em;
  line-height: 2.3em;
  padding-right: 40px !important;
  padding-left: 40px !important;
  display: block;
  white-space: nowrap;
}

.listItem-link:hover {
  text-decoration: none !important;
  color: #1b7ea9 !important;
}

.actionIcon {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 5.2px;
}

.actionIcon:hover {
  background: var(--adi-primary-hover);
  text-decoration: none !important;
}

.caption {
  display: block;
  font-size: 14px;
  color: var(--white);
}

.caption:hover {
  color: black;
  text-decoration: none !important;
}

.nonLette {
  height: 23px;
  width: 23px;
  background-color: var(--adi-red);
  border-radius: 50%;
  font-size: 11px;
  color: white;
  position: absolute;
  text-align: center;
  padding-top: 2px;
}

.dropdown-menu:before {
  border-top: 1px solid var(--prymary) !important;
  border-left: 1px solid var(--prymary) !important;
  top: -10px !important;
  border-radius: 4px 0 0 0 !important;
}

.dropdown-menu {
  border: 1px solid var(--prymary) !important;
}

.linkNotifica, .linkNotifica:hover {
  text-decoration: none !important;
  color: black !important;
}

.apriCentroNotifiche,
.apriCentroNotifiche:hover {
  color: var(--prymary) !important;
  text-decoration: none !important;
  font-size: 14px !important;
  font-weight: bold !important;
  outline: none !important;
}

.dropdown-divider {
  border-top: 1px solid var(--prymary) !important;
}

.dropdown-notifiche {
  width: 24rem !important;
}

.background-mode {
  z-index: -1;
  opacity: 20%;
  pointer-events: none;
  background-color: #F6F7F9 !important;
}

#idDropdownMenuNotifiche {
  will-change: unset !important;
  -webkit-transform: none !important;
          transform: none !important;
  top: 50px !important;
  left: -9rem !important;
  border: 2px solid var(--adi-primary-hover) !important;
  border-radius: 10px;
  z-index: 100;
}

#idDropdownMenuNotifiche.dropdown-menu:before {
  left: 190px;
}

#idDropdownNotifiche.dropdown.show > .dropdown-menu[x-placement=bottom-start] {
  -webkit-animation: none !important;
          animation: none !important;
}

.dropdownMenuDivider {
  border-top: 2px solid #D9DADA !important;
}

button.dropdown-item:active {
  background-color: #E6ECF2 !important;
  outline: none !important;
}

#idDropdownMenuUtente{
  will-change: unset !important;
  -webkit-transform: none !important;
          transform: none !important;
  left: -145px!important;
  border: 2px solid var(--adi-primary-hover) !important;
  border-radius: 10px;
  top: 36px !important;
  z-index: 100;
}

.iconeHeader{
  width: 34px;
  height: 34px;
  margin-bottom: 2px;
}


#idDropdownMenuUtente.dropdown-menu:before {
  left: 171px;
}


@media screen and (max-width: 1199px) {
  #idDropdownMenuUtente{
    top: 50px!important;
    left: -32px!important;
  }

  #idDropdownMenuUtente.dropdown-menu:before {
    left: 53px;
  }

  #idDropdownMenuNotifiche {
    top: 50px!important;
    left: 0 !important;
  }

  #idDropdownMenuNotifiche.dropdown-menu:before {
    left: 25px;
  }
  

}

.bg-blur{
  -webkit-filter: blur(1.5rem);
          filter: blur(1.5rem);
}







/* DATA CALENDAR */



.Calendar_labelTxtInput__Tp4LZ {
    color: #1D1D1B;
    font-size: 14px;
    width: auto;
    padding: 0 2px;
    margin: 0px;
    padding: 0 2px!important;
}

.Calendar_required__1EJGV:after {
    content: "*";
    color: var(--adi-red);
    font-weight: 300;
} 


.Calendar_errorBorder__37Mkk {
    border: 3px solid var(--adi-red) !important;
    border-bottom: 1px solid  var(--adi-red) !important;
    background-color: var(--white);
    font-size: 16px !important;
    color: #1D1D1B !important;
    border-radius: 10px!important;

}

.Calendar_errorMessage__2VCe7 {
    width: 100%;
    margin-top: .25rem;
    font-size: .777rem;
    color: var(--adi-red);
}

.Calendar_inputDataError__KQyeO {
    border: 3px solid var(--adi-red) !important;
    font-size: 14px !important;
    margin: 0 !important;
    border-radius: 10px !important;
}


.Calendar_inputData__1BwGK {
    border: 1px solid var(--prymary) !important;
    font-size: 14px;
    color: #1D1D1B;
    text-align: center !important;
    margin-bottom: 0 !important;
    height: 45px !important;
    border-radius: 10px!important;
}

/*mettere tutte le specifiche per IE qui dentro*/
@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .Calendar_inputData__1BwGK {
        border: 1px solid var(--prymary) !important;
        font-size: 14px;
        color: #1D1D1B;
        text-align: center !important;
        margin-bottom: 0 !important;
        height: 45px !important;
        width: 181px;
        border-radius: 10px!important;
    }
}

.Calendar_inputData__1BwGK:hover {
    font-size: 14px;
    border: 1px solid #0e3f3b !important;
    color: #1D1D1B;
}

.Calendar_inputData__1BwGK:active {
    font-size: 14px;
    border: 1px solid #0e3f3b !important;
    color: #1D1D1B;
}

.Calendar_inputData__1BwGK:focus {
    font-size: 14px;
    border: 1px solid #0e3f3b !important;
    color: #1D1D1B;
}

.Calendar_inputData__1BwGK:disabled {
    font-size: 14px;
    border: 1px solid var(--prymary) !important;
    color: var(--adi-gray-dark);
    border-radius: 10px!important;
}

.Calendar_inputDataDisabled__3Cxyy {
    font-size: 14px;
    border: 1px solid #d6d6d6 !important;
    border-radius: 10px !important;
    color: var(--adi-gray-dark);
    text-align: center !important;
    margin-bottom: 0 !important;
    height: 45px !important;
    background-color: #e6e9f2;
    opacity: 1;
    cursor: not-allowed !important;
    pointer-events: none;
}

.Calendar_ddm__10Vb-::before {
    content: "";
    position: absolute;
    top: -11px;
    left: 70px;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top: 1px solid #0e3f3b;
    border-left: 3px solid #0e3f3b;
}

.Calendar_ddm__10Vb- {
    position: absolute !important;
    will-change: transform;
    top: -40px !important;
    left: 90px !important;
    -webkit-transform: translate3d(125px, 49px, 0px);
            transform: translate3d(125px, 49px, 0px);
}

.Calendar_ddmHeader__1j9Cc::before {
    content: "";
    position: absolute;
    top: -11px;
    left: 70px;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top: 3px solid #0e3f3b;
    border-left: 3px solid #0e3f3b;
}

.Calendar_ddmHeader__1j9Cc {
    position: absolute !important;
    will-change: transform;
    top: -40px !important;
    left: 90px !important;
    -webkit-transform: translate3d(125px, 49px, 0px);
            transform: translate3d(125px, 49px, 0px);
}

.Calendar_dropdown-menu__1ilTP.Calendar_ddmHeader__1j9Cc {
    border: 3px solid #0e3f3b;
    border-radius: 0 !important;
    -webkit-transform: translate3d(-100px, 100px, 0px) !important;
            transform: translate3d(-100px, 100px, 0px) !important;
    padding: 0 !important;
    height: 150px;
}

.Calendar_dropbtn__3S1e6 {
    border-top: 3px solid var(--prymary) !important;
    border-bottom: 3px solid var(--prymary);
    border-left: 3px solid var(--prymary) !important;
    border-right: 3px solid var(--prymary) !important;
    background-color: white;
    color: var(--prymary);
    padding: 16px;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    z-index: 10;
    outline: none !important;
}

.Calendar_dropdown__3q-E0 {
    position: relative;
    display: inline-block;
}

.Calendar_dropdown-content__2eVCw {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    overflow: auto;
    z-index: 1;
    border: 3px solid var(--prymary) !important;
}

.Calendar_dropdown-content__2eVCw a {
    color: var(--prymary);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.Calendar_dropdown__3q-E0 a:hover {
    background-color: white;
}

.Calendar_showDrop__3a0xP {
    display: block;
    position: relative;
    bottom: 3px;
}

.Calendar_opacita__23q_x {
    opacity: 0.5 !important
}

.Calendar_placeholder__2dJft::-webkit-input-placeholder {
    opacity: 0.4 !important;
}

.Calendar_placeholder__2dJft::placeholder {
    opacity: 0.4 !important;
}

.Clock_generalLayout__1decv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid var(--prymary);
    max-height: 45px; /* come le input text*/
    background-color: var(--white);
    border-radius: 10px;
}

.Clock_generalLayout__1decv:hover {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 45px; /* come le input text*/
    background-color: var(--white);
}

.Clock_generalLayout__1decv:active {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 45px; /* come le input text*/
    background-color: var(--white);
}

.Clock_generalLayout__1decv:focus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 45px; /* come le input text*/
    background-color: var(--white);
}

.Clock_generalLayout__1decv:disabled {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #D6D6D6;
    max-height: 45px; /* come le input text*/
    background-color: #D6D6D6;
}

@media screen and (max-width: 762px) {
    .Clock_generalLayout__1decv {
        margin: 0 !important;
    }

    .Clock_spinnersLayout__1Mgqk {
        position: relative !important;
        margin: 0 !important;
        border-top: none;
    }
}

.Clock_timeLayout__27VV4 {
    display: flex;
    max-width: 60%;
}

.Clock_separatorLayout__1zGKQ {
    height: 35px !important;
    margin-top: 0.2px;
    border: transparent !important;
    background-color: white;
    cursor: default;
    max-height: 42px;
    width: 5px !important;
    padding: 0 !important;
}

.Clock_inputTime__hA997 {
    border-top: transparent !important;
    max-height: 42px;
    width: 80% !important;
}

.Clock_hoursLayout__12IAY {
    border-top: transparent !important;
    max-height: 42px;
    width: 40% !important;
}

.Clock_hoursLayout__12IAY:hover {
    color: #886300 !important;
}

.Clock_hoursLayout__12IAY:active {
    color: #6f5100 !important;
}

.Clock_hoursLayout__12IAY:focus {
    color: #886300 !important;
}

.Clock_hoursLayout__12IAY:disabled {
    color: var(--adi-gray-dark) !important;
}

.Clock_minuteLayout__e4kNF {
    border-top: transparent !important;
    max-height: 42px;
    width: 40% !important;
}

.Clock_minuteLayout__e4kNF:hover {
    color: #886300 !important;
}

.Clock_minuteLayout__e4kNF:active {
    color: #6f5100 !important;
}

.Clock_minuteLayout__e4kNF:focus {
    color: #886300 !important;
}

.Clock_minuteLayout__e4kNF:disabled {
    color: var(--adi-gray-dark) !important;
}

.Clock_iconTimeLayout__vwj_d {
    cursor: pointer;
}

.Clock_spinnersLayout__1Mgqk {
    border: 1px solid var(--prymary);
    /*border-top: none;*/
    position: absolute;
    z-index: 1;
    width: calc(100% - 12px);
    background: #fff;
    bottom: -40px;
    border-radius: 10px;
}

.Clock_hourSpinner__2sV1r {
    border-right: white !important;
    border-bottom: white !important;
}

.Clock_hourSpinner__2sV1r:hover {
    color: #886300 !important;
}

.Clock_hourSpinner__2sV1r:active {
    color: #6f5100 !important;
}

.Clock_hourSpinner__2sV1r:focus {
    color: #886300 !important;
}

.Clock_hourSpinner__2sV1r:disabled {
    color: var(--adi-gray-dark) !important;
}

.Clock_minuteSpinner__XjPjC {
    border-left: white !important;
    border-bottom: white !important;
}

.Clock_minuteSpinner__XjPjC:hover {
    color: #886300 !important;
}

.Clock_minuteSpinner__XjPjC:active {
    color: #6f5100 !important;
}

.Clock_minuteSpinner__XjPjC:focus {
    color: #886300 !important;
}

.Clock_minuteSpinner__XjPjC:disabled {
    color: var(--adi-gray-dark) !important;
}

.RadioButton_radioButtonContainerRow__2rPXJ {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}

@media screen and (max-width: 675px) {
  .RadioButton_radioButtonContainerRow__2rPXJ {
    display: inline;
  }
}

.RadioButton_radioButtonContainerCol__1Iye2 {
  min-width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.RadioButton_fontRadioButton__2alqu {
  font-size: 16px !important;
  color: #1D1D1B !important;
}

.CheckBox_checkBoxContainer__1mHmq {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
}

.CheckBox_fontCheckBox__HHro4 {
    font-size: 14px;
    color: #1D1D1B !important;
}

.CheckBox_removeMargin__3zApu {
    min-height: 2em;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.CheckBox_removeLabelMargin__PZk6s {
    margin-bottom: 0 !important;
}

.CheckBox_form-check__2i8Hw [type=checkbox] + label {
    font-size: 14px;
}

.Popover_tooltips__18POH  {
    background: var(--prymary);
    color: var(--white);
    padding: 10px;
    font-size: 0.8em;
    border-radius: 5px;
}

.Popover_textPopover__1m59L{
    text-decoration: none;
    cursor: pointer;
}

.Popover_popover-ellipsis__1DIyq {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;
    height: 25px;
    display: block;
}

.Popover_popover__2PQF0 {
    z-index: 99 !important;
}

.Popover_arrow__1bjDp {

}


.Autocomplete_mandatory__2oJqJ {
    font-size: 14px;
    color: #D83D3D;
    background: #fff;
    font-style: italic;
    padding: 0 2px!important;
}

.Autocomplete_autocomplete__2taqj {
    border: 9px solid #999;
    padding: 0.5rem;
    width: 300px;
}

.Autocomplete_noSuggestions__hMSAr {
    color: var(--adi-red);
    font-size: 12px;
    position: absolute;
    padding-left: 0.5rem;
    padding-top: 0.1rem;
}

.Autocomplete_suggestions__20kiu {
    border-top-width: 0 !important;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
    color: var(--prymary);
}

.Autocomplete_suggestions__20kiu li {
    padding: 0.5rem;
}

.Autocomplete_suggestionActive__-gl6b {
    background-color: white;
    cursor: pointer;
    border-bottom: 0 !important;
}

.Autocomplete_suggestionSelected__3bKFw {
    background-color: var(--prymary);
    color: var(--white);
    cursor: pointer;
    border-bottom: 0 !important;
    text-decoration: underline;
}

.Autocomplete_suggestions__20kiu li:hover {
    background-color: var(--prymary);
    color: white;
    cursor: pointer;
    border-bottom: 0 !important;
    text-decoration: underline;
}

.Autocomplete_suggestions__20kiu li:not(:last-of-type) {
    border-bottom: 1px solid #999;
}

.Autocomplete_emptySuggestions__2GdF0 {
    border-top-width: 0 !important;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
}

.Autocomplete_noOptionsMessageInDropdown__2i4BM {
    font-size: 14px !important;
    color: #99999F !important;
    text-align: center !important;
    padding: 0.5rem !important;
}

.RoleBasedComponents_addValueButton__2n8_2 {
    height: 45px;
    width: 100%;
    text-align: left;
    border: 0;
    background: none;
    color: var(--prymary);
    font-size: 14px;
    outline: 0;
}

.RoleBasedComponents_addValueButton__2n8_2 svg {
    width: 24px;
    height: 24px;
}

.RoleBasedComponents_historyLabel__JYb94 {
    font-size: 14px;
}

.RoleBasedComponents_historyLabelInfos__sczmM {
    font-size: 12px;
}

.RoleBasedComponents_fieldContainer__2umyT {
    min-height: 45px;
}

.RoleBasedComponents_debug__g6Lun {
    font-size: 12px;
    font-weight: bold;
    padding: 3px;
}

.RoleBasedComponents_debugError__27Do8 {
    color: white;
    background: red;
}

.RoleBasedComponents_debugInfo__2ikLk {
    color: black;
    background: lightgray;
}

.RoleBasedComponents_debugOk__1MdEE {
    color: white;
    background: green;
}

.RoleBasedComponents_availableCharsLabel__2zMci {
    opacity: 0.5;
    font-size: 14px;
    font-weight: 300;
}

.RoleBasedComponents_inputInvalidText__2-2uH {
    font-size: 14px;
    color: var(--adi-red);
    position: absolute;
    background: white;
    font-style: italic;
    z-index: 2;
    width: 200px;
}

.RoleBasedComponents_inputLabelAlert__31jBC {
    font-size: 14px;
    font-weight: bold;
    color: black;
    position: absolute;
    background: white;
    z-index: 2;
}

.RoleBasedComponents_inputMissingValue__3osMK {
    font-size: 14px;
    color: var(--adi-red);
    background: white;
    font-style: italic;
    font-weight: 300;
}

.RoleBasedComponents_phonePrefix__2c0C9 {
    background-color: #F0F0F0;
    border: 1px solid var(--prymary);
    border-radius: 10px;
    margin-right: 1px;
}

.RoleBasedComponents_phonePrefix__2c0C9 label {
    margin: 9px 5px 0;
    color: black;
}

.RoleBasedComponents_clickable__3upm9 {
    cursor: pointer;
}

.RoleBasedComponents_iconColor__3234D {
    fill: var(--prymary)
}

/* HISTORY SIDEBAR */

.RoleBasedComponents_modal__2VNi2 {
    opacity: 1 !important;
}

.RoleBasedComponents_modal__2VNi2 .RoleBasedComponents_modal-dialog__1SDVS {
    right: -25vw;
    max-height: 100%;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.RoleBasedComponents_modal__2VNi2.RoleBasedComponents_show__1z7cJ .RoleBasedComponents_modal-dialog__1SDVS {
    right: 0;
}

.RoleBasedComponents_modal-dialog__1SDVS {
    top: 0;
    position: fixed;
    margin: auto;
    width: 25vw !important;
    max-width: 25vw !important;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.RoleBasedComponents_modal-title__1rZ0s {
    width: 100%;
}

.RoleBasedComponents_modal-title__1rZ0s button {
    opacity: 1;
    outline: 0 !important;
    color: var(--prymary);
    font-size: 18px;
    font-weight: bold;
}

.RoleBasedComponents_modal-title__1rZ0s svg {
    fill: var(--prymary);
}

.RoleBasedComponents_modal-body__2ao2l {
    padding: 0 !important;
    margin-top: 15px;
}

.RoleBasedComponents_modal__2VNi2 .RoleBasedComponents_modal-dialog__1SDVS .RoleBasedComponents_modal-content__eRVCJ {
    height: 100%;
    overflow-y: auto;
    border: none;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
}

.RoleBasedComponents_modal-content__eRVCJ {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .5);
    outline: 0;
}

.RoleBasedComponents_modal-backdrop__1z8fs {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0;
}

.RoleBasedComponents_modal-backdrop__1z8fs.RoleBasedComponents_show__1z7cJ {
    opacity: 0 !important;
}

.RoleBasedComponents_fade__1t5FA {
    transition: opacity .15s linear;
}

.RoleBasedComponents_avatarPiccolo__2BOTc {
    flex-grow: 1;
    width: 34px;
}

.RoleBasedComponents_timestamp__2Z1Ba {
    font-size: 11px;
}

.RoleBasedComponents_fieldHeavilyEditedWarningText__20odh {
    color: orangered;
    font-weight: bold;
    font-size: 14px;
}

.RoleBasedComponents_fieldHeavilyEditedWarningIcon__VPudX {
    fill: orangered !important;
    margin-right: 4px;
}

.RoleBasedComponents_sidebarItem__k98Pc {
    min-height: 110px;
    font-size: 14px;
}

.RoleBasedComponents_sidebarItem__k98Pc:nth-child(odd) {
    background-color: #F6F7F9 !important;
}

.Header_headerContainer__37sY6 {
    height: 55px;
    background-color: var(--adi-gray-300);
    padding: 0 30px;
    color: var(--white);
}

.Header_styleHeader__3d5iP {
    color: #19191A;
    font-size: 18px;
}

.Header_headerImg__3hE5w {
    width: 208px;
    height: 24px;
}

.Header_stileProfilo__AGJJX {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    flex-direction: row;
}

.Header_labelNomeApplicazione__26aKB {
    color: var(--white);
    font-size: 20px;
}

/* risoluzione per dispositivi con schermo small ed extrasmall */
@media screen and (max-width: 576px) {
    .Header_headerImg__3hE5w {
        width: 120px;
    }

    .Header_labelNomeApplicazione__26aKB {
        white-space: break-spaces;
    }
}

/* risoluzione per dispositivi con schermo medium */
@media screen and (min-width: 577px) and (max-width: 768px) {
    .Header_headerImg__3hE5w {
        width: 160px;
    }
}
.Header_nomeUtente__3kJ02,
.Header_profiliUtente__3RySW > span {
    max-width: 300px;
}

@media screen and (max-width: 1400px) {
    .Header_profiliUtente__3RySW, .Header_utenteLoggato__3v2ha {
        font-size: 10px !important;
    }
    .Header_nomeUtente__3kJ02,
    .Header_profiliUtente__3RySW > span {
        max-width: 150px;
    }
}

.Header_profiliUtente__3RySW {
    text-overflow: ellipsis !important;
    display: -webkit-box;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden;
}

.Table_azTable__2J2XI {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    overflow: auto;
}

.Table_azTableHeader__1hRJQ {
    color: var(--prymary);
    border-top: 3px solid var(--prymary);
    width: 15%;
    background: white;

}

.Table_azTableHeaderCell__11nC2 {
    min-width: 100px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    font-size: 14px !important;
    color: var(--prymary);
}


.Table_azTableHeaderCellMinWidth__ZeKjk {
    cursor: pointer;
    font-size: 14px !important;
    color: var(--prymary);
    min-width: 108px !important;
    text-align: center !important;
    height: auto;
    padding: 1rem 5px 1rem 5px;
}

.Table_azTableHeaderCellMinWidthRicercaAssistito__CkiLi {
    cursor: pointer;
    font-size: 14px !important;
    color: #a8a8a8;
    min-width: 108px !important;
    text-align: center !important;
}


.Table_azTableHeaderCellMinWidthHomePage__27hHv {
    cursor: pointer;
    font-size: 14px !important;
    color: var(--prymary);
    min-width: 108px !important;
    text-align: center !important;
    height: auto;
    padding: 0.5rem 5px 0.5rem 5px;
}

.Table_azTableCell__2HXkH {
    font-size: 12px;
    text-align: center;
    height: 40px;
    padding: 5px;
    min-width: 50px;
    vertical-align: middle !important;
    color: black;

    /* Ellipsis su tutte le celle della tabella */
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Table_azTableCellTwo__38vSq {
    font-size: 14px;
    text-align: center;
    height: 40px;
    padding: 5px;
    min-width: 108px;
    vertical-align: middle !important;

}

.Table_azTableCellDropdown__3gsBJ {
    font-size: 14px;
    text-align: center;
    height: 40px;
    padding: 5px;
    min-width: 108px;
    overflow: visible;
    vertical-align: middle !important;
}

.Table_azTableCellCheckbox__2ejXY {
    text-align: center;
    height: 40px;
    padding: 0 !important;
    margin-top: 0 !important;
    vertical-align: middle !important;
}


.Table_paginationContainer__TpBN3 {
    float: right;
}

.Table_hideScrollbar__3vo8i::-webkit-scrollbar {
    display: none;
}

.Table_hideScrollbar__3vo8i {
    margin: auto
}

.Table_legendContainer__zS4C- {
    float: left;
    min-height: 74px;
    padding-top: 16px;
}

.Table_stickerGreenOp4__2Es42{
    color:var(--prymary);
    opacity: 0.4;
}

.Table_stickerGreen__3Lg_t {
    /* width: 20px;
    height: 20px;
    background-color: var(--prymary);
    border-radius: 85%;
    border: 2px solid var(--prymary);
    margin: auto; */
    color:var(--prymary);
}

.Table_stickerYellow__1o3ga {
    /* width: 20px;
    height: 20px;
    background-color: #F4B740;
    border-radius: 85%;
    border: 2px solid #F4B740;
    margin: auto; */
    color:var(--adi-yellow-100);
}

.Table_stickerRed__elxbf {
    /* width: 20px;
    height: 20px;
    background-color: var(--adi-red);
    border-radius: 85%;
    border: 2px solid var(--adi-red);
    margin: auto; */
    color: var(--adi-red);
}

.Table_stickerFillWarning__dIOgV {
    /* width: 22px;
    height: 22px;
    color: white;
    fill: white;
    font-weight: bold;
    padding-left: 40%;
    padding-top: 10%;
    cursor: default; */
}

.Table_stickerFillVerificato__3CiDM {
    /* width: 22px;
    height: 22px;
    color: white;
    fill: white;
    font-weight: bold;
    padding-left: 23%;
    padding-top: 10%;
    cursor: default; */
}

.Table_stickerFill__3hsw1:hover {
    text-decoration: none;
}

.Table_stickerGrey__1TcEf {
    width: 18px;
    height: 18px;
    background-color: grey;
    border-radius: 85%;
    border: 2px solid grey;
    margin: auto;
}

.Table_stickerTrattino__15lGt {
    width: 10px;
    background-color: black;
    border-radius: 85%;
    border: 1px solid black;
    margin: auto;
}

@media screen and (max-width: 600px) {
    .Table_azTable__2J2XI {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid;
    }


}

@media screen and (max-width: 1200px) {
    .Table_azTableCellTwo__38vSq {
        /*border: none !important;*/
        font-size: 14px;
        text-align: center;
        height: 40px;
        /* padding-right: 20px; */
        min-width: 108px;
    }
}

@media screen and (max-width: 1480px) {
    .Table_azDiv__JMyjW {
        overflow-x: auto;
    }
}

.Table_scrollCols__c0x0_ {
    overflow-x: auto;
}

/*mettere tutte le specifiche per IE qui dentro*/
@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    th {
        font-weight: bold;
    }
}

.Table_tableTitle__3oTf_ {
    font-weight: bold;
    margin-bottom: 1rem;
}

.Table_informationLegend__3HvWc {
    margin-top: 5rem;
    margin-left: 1rem;
}

.Table_bgColorWhite__2mf41 {
    background-color: white !important;
}

.Table_ellipsisLabel__1wKaa {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.Table_linkToTd__2fMwy {
    cursor: pointer;
    text-decoration: underline;
    color: var(--prymary);
    background: transparent;
    border: none;
}

.Table_disabledCursor__1LYFB {
    cursor: default;
}

.Icon_icon-blue__Hhova {
    color: lightskyblue !important;
}

.Icon_bg-yellow-dark__3FteC {
    background-color: #e0cf3329 !important;
}
  
.AccordionAvvioRichiesta_collapseHeader__1k7d_ {
    border: 1px solid #e3e4e6;
    border-radius: 10px;
    box-shadow: 0px 3px 5px #dedede;
    border-top: 1px solid #e3e4e6 !important;
}

.AccordionAvvioRichiesta_requiredFieldsMissing__pMww7 {
    color: var(--adi-red);
    font-weight: 500;
    margin: 0;
    margin-right: 24px;
}

.AccordionAvvioRichiesta_title__18aac {
    color: var(--prymary);
    text-decoration: underline;
    font-size: 14px;
    font-weight: 700;
}

.AccordionAvvioRichiesta_aggiungiInformazioneAggiuntiva__3Vw9n {
    color: var(--prymary);
    text-decoration: underline;
    font-size: 14px;
    font-weight: 700;
}

.Statistica_container__2J2WK {
    border: 0 solid black;
}

div.Statistica_linkQuestionario__3N7Oj {
    cursor: pointer;
    text-decoration: underline;
    color: var(--prymary);
    font-weight: 700;
}

div.Statistica_linkQuestionario__3N7Oj:hover {
    color: var(--adi-primary-hover);
}

.Statistica_requiredField__3wKSf {
    font-size: 14px;
    color: var(--adi-red);
    background: #fff;
    font-style: italic;
    font-weight: 500;
}

.AccordionSezioneRichiesta_titleSezione__1W7we {
    color: var(--black)!important;
    font-size: 14px;
    margin: 0px;
}

.AccordionSezioneRichiesta_titleSezioneNotAccordion__1_Xr6 {
    font-weight: 800;
}

.AccordionSezioneRichiesta_requiredFields__2ngRH {
    color: var(--adi-red);
    font-weight: 500;
    margin: 0px;
    margin-right: 10px;
}

.AccordionSezioneRichiesta_sezioneAccordionBody__2zl5g {
    border: 1px solid #e3e4e6;
    box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 51%);
    border-radius: 22px;
}

.ProposteAttivitaSuggerimentiSection_inputMissingValue__2oeye {
    font-size: 14px;
    color: var(--adi-red);
    background: white;
    font-style: italic;
}
.Box_boxAccordion__IbIz8 {
    border: 1px solid;
    padding: 10px; 
    margin-bottom: 10px;
    margin-top: 10px;
}
.Breadcrumb_stickyBar__15mVt {
    background-color: var(--adi-gray-400);
    color: var(--prymary);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 51;
}

.buttonsBoxStick_stickBottom__y4gnH{
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    margin: auto;
    width: 95%;
    align-content: stretch;
    justify-content: stretch;
    flex-grow: 3;
    min-width: 555px;
}

.buttonsBoxStick_buttonsBoxStickContainer__1evYy{
    position: relative;
    margin-top: 10vh;
}
.ModalHelper_h5custom__1Dg55 {
  line-height: 4;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-size: 14px;
  font-weight: 600;
  background: var(--prymary);
  color: var(--white);
  text-align: center;
}

.ModalHelper_clickDisabled__2PPFF {
  pointer-events: none!important;
}

.ModalHelper_transform__xkd57 {
  -webkit-transform:scale(0.9);
  transform:scale(0.9);
  -webkit-transform-origin:0 0;
  transform-origin:0 0;
}

.ModalHelper_containerConsenso__Tm4M7 {
  text-align: center;
  border-left: 3px solid var(--prymary) !important;
  border-right: 3px solid var(--prymary) !important;
}

.ModalHelper_containerModale__3-Lx- {
  /*border: 3px solid var(--prymary) !important;*/
  height: 30vh !important;
  width: 30vw !important;
}

.ModalHelper_backdrop__3Shc4 {
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.75;
  width: 100vw;
  height: 100vh;
}

.ModalHelper_containerOscura__1h-ZE {
  text-align: center;
  border-left: 3px solid #986f00 !important;
  border-right: 3px solid #986f00 !important;
}

.ModalHelper_containerViola__25IF_ {
  text-align: center;
  border-left: 3px solid #bf023d !important;
  border-right: 3px solid #bf023d !important;
}

.ModalHelper_buttons__1Kuwy {
  width: 100% !important;
}

.ModalHelper_buttonAnnulla__33n_7 {
  width: 50% !important;
  font-size: 14px !important;
}

.ModalHelper_buttonOk__3aGvb {
  font-size: 14px !important;
  width: 50% !important;
}

.ModalHelper_modalContentCustom__1pVx9 {
  /*min-width: 500px;*/
  text-align: center !important;
}

.ModalHelper_modalContainerCustom__u5Igr {
  width: 100% !important;
}

.ModalHelper_modalBodyCustom__2s40z{
  text-align: left !important;
  font-size: 0.8rem !important;
}

.ModalHelper_modalConsenso__1DGtI {
  height: 100%;
  border: 2px solid var(--prymary);
}

.ModalHelper_modalHeader__3mTP_ {
  width: 100%;
  background-color: var(--prymary);
  color: white;
  border-radius: 0;
}

.ModalHelper_modalHeader__3mTP_ h5 {
  width: 100%;
  background-color: var(--prymary);
  height: 50px;
  color: white;
  font-size: 14px;
  line-height: 3;
}

.ModalHelper_formGroup__3zRJ-.ModalHelper_form-group__3ZQ7Z{
  border: 3px solid var(--prymary);
  height: 10px !important;
}

.ModalHelper_btnAnnulla__26J3- {
  margin-bottom: 9px;
  font-size: 12px;
  width: 50%;
  margin-right: 5px;
  border: 1px solid;
}


.ModalHelper_btnConsenso__3EWoK {
  width: 50%;
  margin-left: 5px;
  font-size: 14px;
  height: 60%;
}

.ModalHelper_modalFooter__3xpLh {
  border-right: 3px solid var(--prymary);
  border-left: 3px solid var(--prymary);
  border-bottom: 3px solid var(--prymary);
  border-radius: 0;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.ModalHelper_triangle-down-modal-consenso__3AvtY {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 25px solid var(--prymary);
  margin: 0 auto;
}

/* Modale oro oscura doc*/

.ModalHelper_modalOscura___xq9K {
  height: 100%;
  border: 2px solid #986f00;
}

.ModalHelper_modalHeaderOscura__iCDKl {
  width: 100%;
  background-color: #986f00;
  height: 50px;
  color: white;
  border-radius: 0;
}

.ModalHelper_modalHeaderOscura__iCDKl h5 {
  background-color: #986f00;
  width: 100%;
  height: 50px;
  color: white;
  font-size: 14px;
  line-height: 3;
}

.ModalHelper_modalBodyOscura__2AVou {
  border-right: 3px solid #986f00;
  border-left: 3px solid #986f00;
}

.ModalHelper_triangle-down-modal-oscura__1lPUN {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 25px solid #986f00;
  margin: 0 auto;
}

.ModalHelper_modalFooterOscura__SRvSs {
  border-right: 3px solid #986f00;
  border-left: 3px solid #986f00;
  border-bottom: 3px solid #986f00;
  border-radius: 0;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.ModalHelper_btnConsensoOscura__1x09t {
  width: 50%;
  margin-left: 5px;
  font-size: 14px;
  height: 60%;
  background-color: #986f00;
  border: 0px;
  outline: 0;
}

.ModalHelper_btnConsensoOscura__1x09t:hover {
  width: 50%;
  margin-left: 5px;
  font-size: 14px;
  height: 60%;
  background-color: #986f00;
  border: 0px;
}

.ModalHelper_btnConsensoOscura__1x09t:active {
  width: 50%;
  margin-left: 5px;
  font-size: 14px;
  height: 60%;
  background-color: #986f00 !important;
  border: 1px solid #986f00 !important;
}

.ModalHelper_btnConsensoOscura__1x09t:focus {
  outline: 0;
  -webkit-box-shadow: none !important;
  background-color: #986f00;
  box-shadow: inset 0 0 0 2px #986f00;
  background-color: #986f00 !important;
  color: white !important;
  border: 1px solid #986f00;
}

.ModalHelper_btnAnnullaOscura__bVZnL {
  margin-bottom: 9px;
  font-size: 12px;
  width: 50%;
  margin-right: 5px;
  border: 1px solid;
  box-shadow: inset 0 0 0 2px #986f00;
  color: #986f00;
}

.ModalHelper_btnAnnullaOscura__bVZnL:hover {
  margin-bottom: 9px;
  font-size: 12px;
  width: 50%;
  margin-right: 5px;
  border: 1px solid #986f00;
  box-shadow: inset 0 0 0 2px #986f00;
  color: #986f00;
}

.ModalHelper_btnAnnullaOscura__bVZnL:focus {
  outline: 0;
  -webkit-box-shadow: none !important;
  margin-bottom: 9px;
  font-size: 12px;
  width: 50%;
  margin-right: 5px;
  border: 1px solid #986f00;
  box-shadow: inset 0 0 0 2px #986f00;
  color: #986f00 !important;
}

/* Modale VIOLA*/

.ModalHelper_modalViola__3DfzP {
  height: 100%;
  border: 2px solid #bf023d;
}

.ModalHelper_modalHeaderViola__1_Pil {
  width: 100%;
  background-color: #bf023d;
  height: 50px;
  color: white;
  border-radius: 0;
}

.ModalHelper_modalHeaderViola__1_Pil h5 {
  background-color: #bf023d;
  width: 100%;
  height: 50px;
  color: white;
  font-size: 14px;
  line-height: 3;
}

.ModalHelper_modalBodyViola__2Oi0D {
  border-right: 3px solid #bf023d;
  border-left: 3px solid #bf023d;
}

.ModalHelper_triangle-down-modal-viola__1_E71 {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 25px solid #bf023d;
  margin: 0 auto;
}

.ModalHelper_modalFooterViola__6JsUB {
  border-right: 3px solid #bf023d;
  border-left: 3px solid #bf023d;
  border-bottom: 3px solid #bf023d;
  border-radius: 0;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.ModalHelper_btnAnnullaViola__3P0hL {
  margin-bottom: 9px;
  font-size: 12px;
  width: 50%;
  margin-right: 5px;
  border: 1px solid;
  box-shadow: inset 0 0 0 2px #bf023d;
  color: #bf023d;
}

.ModalHelper_btnAnnullaViola__3P0hL:hover {
  margin-bottom: 9px;
  font-size: 12px;
  width: 50%;
  margin-right: 5px;
  border: 1px solid #bf023d;
  box-shadow: inset 0 0 0 2px #bf023d;
  color: #bf023d;
}

.ModalHelper_btnAnnullaViola__3P0hL:focus {
  outline: 0;
  -webkit-box-shadow: none !important;
  margin-bottom: 9px;
  font-size: 12px;
  width: 50%;
  margin-right: 5px;
  border: 1px solid #bf023d;
  box-shadow: inset 0 0 0 2px #bf023d;
  color: #bf023d !important;
}

.ModalHelper_btnConsensoViola__2y6is {
  width: 50%;
  margin-left: 5px;
  font-size: 14px;
  height: 60%;
  background-color: #bf023d;
  border: 0px;
  color: white;
}

.ModalHelper_btnConsensoViola__2y6is:hover {
  width: 50%;
  margin-left: 5px;
  font-size: 14px;
  height: 60%;
  background-color: #bf023d;
  border: none;
  color: white;
  box-shadow: var(--adi-gray-dark);
}

.ModalHelper_btnConsensoViola__2y6is:active {
  color: white;
  outline: 0;
}

.ModalHelper_btnConsensoViola__2y6is:focus {
  background-color: #bf023d;
  outline: 0;
  box-shadow: none;
  -webkit-box-shadow: none !important;
}

/* PULSANTI CSS */

.ModalHelper_btnGreenOutline__23wDC {
  
  background-color: var(--white);
  border: 3px solid var(--prymary);
  color: var(--prymary) !important;
  box-shadow: none;
}

.ModalHelper_btnGreenOutline__23wDC:hover {
  
  background-color: #deecf2;
  border: 3px solid #0e3f3b;
  color: #0e3f3b !important;
  box-shadow: none;
}

.ModalHelper_btnGreenOutline__23wDC:active {
  background-color: #deecf2;
  border: 3px solid #0e3f3b;
  color: #084662 !important;
  box-shadow: none;
}

.ModalHelper_btnGreenOutline__23wDC:focus {
  
  background-color: #deecf2;
  border: 3px solid #0e3f3b;
  color: #0e3f3b !important;
  box-shadow: none;
  -webkit-box-shadow: none !important;
}

.ModalHelper_btnGreenOutline__23wDC:disabled {
  
  background-color: var(--white);
  border: #d6d6d6;
  color: var(--adi-gray-dark) !important;
}

/* BTN PERICOLO VIOLA */
.ModalHelper_btnViola__3TlXD {
  
  background-color: #bf023d !important;
  color: var(--white) !important;
  border: 1px solid #bf023d !important;
  box-shadow: none;
}

.ModalHelper_btnViola__3TlXD:hover {
  
  background-color: #9f0636 !important;
  color: var(--white) !important;
  border: 1px solid #9f0636 !important;
  box-shadow: none;
}

.ModalHelper_btnViola__3TlXD:active {
  
  background-color: #890a32 !important;
  color: var(--white) !important;
  border: 1px solid #890a32 !important;
}

.ModalHelper_btnViola__3TlXD:focus {
  
  background-color: #bf023d !important;
  color: var(--white) !important;
  border: 1px solid #890a32 !important;
  outline: 0;
  box-shadow: none;
  -webkit-box-shadow: none !important;
}

.ModalHelper_btnViola__3TlXD:disabled {
  
  background-color: #d6d6d6;
  color: var(--white) !important;
  border: #d6d6d6;
}

/* BTN PERICOLO VIOLA OUTLINE */
.ModalHelper_btnOutlineViola__3pDYn {
  
  background-color: var(--white);
  border: 3px solid #bf023d;
  color: #bf023d !important;
  box-shadow: none;
}

.ModalHelper_btnOutlineViola__3pDYn:hover {
  
  background-color: #f8eef1;
  border: 3px solid #9f0636;
  color: #9f0636 !important;
  box-shadow: none;
}

.ModalHelper_btnOutlineViola__3pDYn:active {
  
  background-color: #f6e2e8;
  border: 3px solid #890a32;
  color: #890a32 !important;
}

.ModalHelper_btnOutlineViola__3pDYn:focus {
  
  background-color: #f8eef1;
  border: 3px solid #9f0636;
  color: #9f0636 !important;
  box-shadow: none;
  -webkit-box-shadow: none !important;
}

.ModalHelper_btnOutlineViola__3pDYn:disabled {
  
  background-color: var(--white);
  border: #d6d6d6;
  color: var(--adi-gray-dark) !important;
}

/* BTN WARNING GIALLO */

.ModalHelper_btnWarning__XNkGi {
  
  background-color: #986f00 !important;
  color: var(--white) !important;
  border: 1px solid #986f00 !important;
  box-shadow: none;
}

.ModalHelper_btnWarning__XNkGi:hover {
  
  background-color: #886300 !important;
  color: var(--white) !important;
  border: 1px solid #886300 !important;
  box-shadow: none;
}

.ModalHelper_btnWarning__XNkGi:active {
  
  background-color: #6f5100 !important;
  color: var(--white) !important;
  border: 1px solid #6f5100 !important;
  box-shadow: none;
}

.ModalHelper_btnWarning__XNkGi:focus {
  
  background-color: #986f00 !important;
  color: var(--white) !important;
  border: 1px solid #6f5100 !important;
  outline: 0;
  box-shadow: none;
  -webkit-box-shadow: none !important;
}

.ModalHelper_btnWarning__XNkGi:disabled {
  
  background-color: #d6d6d6;
  color: var(--white);
  border: #d6d6d6;
}

/* BTN WARNING OUTLINE */
.ModalHelper_btnWarningOutline__shzRk {
  
  background-color: var(--white);
  border: 3px solid #986f00;
  color: #986f00 !important;
  box-shadow: none;
}

.ModalHelper_btnWarningOutline__shzRk:hover {
  
  background-color: #f2ecde;
  border: 3px solid #886300;
  color: #886300 !important;
  box-shadow: none;
}

.ModalHelper_btnWarningOutline__shzRk:active {
  
  background-color: #ede3c9;
  border: 3px solid #6f5100;
  color: #6f5100 !important;
}

.ModalHelper_btnWarningOutline__shzRk:focus {
  
  background-color: #f2ecde;
  border: 3px solid #886300;
  color: #886300 !important;
  box-shadow: none;
  -webkit-box-shadow: none !important;
}

.ModalHelper_btnWarningOutline__shzRk:disabled {
  
  background-color: var(--white);
  border: #d6d6d6;
  color: var(--adi-gray-dark);
}

.AnamnesiFisiologica_aggiungiAccordion__1Tsjx {
    cursor: pointer;
    color: var(--prymary) !important;
}

.AnamnesiPatologicaProssimaSottoAccordion_bottomPlaceholder__29MF4 {
    opacity: 0.5;
    font-size: 14px;
}

.AnamnesiPatologicaProssimaSottoAccordion_noDiagPrincMessage__1OZKX {
    font-size: 14px;
    color: var(--adi-red);
    font-style: italic
}
.Accordion_transition__3_SG-{
    transition: height 2s;
}

.Accordion_header__2kmtx {
    background-color: var(--white);
}

.Accordion_btnheader__3r8Ga {
    color: var(--white) !important;
}

.Accordion_noBorder__1xJ9a {
    border: none !important;
}

.Accordion_border__mj131 {
    border: 0.5px solid var(--adi-gray-dark);
    min-height: 180px;
}

.PopupDataDimissioneProgrammataAggiornata_modalBtnStyle__jIVi3 {
    width: 9vw !important;
    height: 2vw !important;
    font-weight: bold;
}

.PopupDataDimissioneProgrammataAggiornata_modalBtnStyle__jIVi3:active, .PopupDataDimissioneProgrammataAggiornata_modalBtnStyle__jIVi3:hover, .PopupDataDimissioneProgrammataAggiornata_modalBtnStyle__jIVi3:focus {
    color: white !important;
}

.PopupDataDimissioneProgrammataAggiornata_modalOrange__3ZEoe {
    border: 0.1vw solid var(--adi-orange) !important;
    color: var(--adi-orange) !important;
}

.PopupDataDimissioneProgrammataAggiornata_modalGreen__2tuG8 {
    border: 0.1vw solid var(--prymary) !important;
    color: var(--prymary) !important;
}

.PopupDataDimissioneProgrammataAggiornata_modalOrange__3ZEoe:active, .PopupDataDimissioneProgrammataAggiornata_modalOrange__3ZEoe:hover, .PopupDataDimissioneProgrammataAggiornata_modalOrange__3ZEoe:focus {
    background-color: var(--adi-orange) !important;
}

.PopupDataDimissioneProgrammataAggiornata_modalGreen__2tuG8:active, .PopupDataDimissioneProgrammataAggiornata_modalGreen__2tuG8:hover, .PopupDataDimissioneProgrammataAggiornata_modalGreen__2tuG8:focus {
    background-color: var(--prymary) !important;
}

.PopupDataDimissioneProgrammataAggiornata_modalHeader__2pFLl {
    color: white;
    font-weight: bold;
    padding: 0.8vw !important;
    font-size: 14px !important;
}

.PopupDataDimissioneProgrammataAggiornata_modalBody__2ewwQ {
    vertical-align: middle !important;
    text-align: center;
    white-space: pre-wrap;
}

.PopupDataDimissioneProgrammataAggiornata_modalContent__30vQr {
    height: 21vw !important;
}

.PopupDataDimissioneProgrammataAggiornata_fontSizeText__1AHyZ {
    font-size: 14px !important;
}

.PopupDataDimissioneProgrammataAggiornata_backgroundOrange__1rBKe {
    background-color: var(--adi-orange) !important;
}

.PopupDataDimissioneProgrammataAggiornata_backgroundGreen__2Eb5B {
    background-color: var(--prymary) !important;
}

.PopupDataDimissioneProgrammataAggiornata_modalDialog__1BsxA {
    max-width: 30vw !important;
    height: 100% !important;
}

.PopupDataDimissioneProgrammataAggiornata_modalFooter__2C-xf {
    padding-bottom: 1.6vw !important;
}
.Trackbar_stickyBar__2A5Z6 {
    position: -webkit-sticky;
    position: sticky;
    top: 36px;
    z-index: 51;
    border: 1px solid rgb(220 220 220);
    background-color: var(--white);
}

.Trackbar_circle__DM5HP {
    border: 1px solid grey;
    width: 40px;
    height: 40px;
    display: table-cell;
    overflow: hidden;
    vertical-align: middle;
    text-align: center;
}

.MenuAccordion_navbarCustomStyle__1CfBj {
    background: var(--adi-gray-300)!important;
    width: 100px;
}

.MenuAccordion_roundedIcon__2zd2S {
    border: 1px solid #FFFFFF;
    width: 50px;
    height: 50px;
    background-color: transparent;
}

.MenuAccordion_listFirstLevelAccordions__1QBwa {
    width: 90%;
    font-size: 14px;
    margin-left: 90px;
    height: 70vh;
    position: relative;
}

.MenuAccordion_listFirstLevelAccordions__1QBwa > li {
    color: var(--white) !important;
    background-color: transparent !important;
    border: none;
    padding: 0.2rem 0.0rem;
}

.MenuAccordion_listFirstLevelAccordions__1QBwa > li:hover {
    color: var(--black) !important;
    font-weight: bold;
    background-color: #cccccc !important;
}

.MenuAccordion_listFirstLevelAccordions__1QBwa::-webkit-scrollbar {
    width: 10px;
}

.MenuAccordion_listFirstLevelAccordions__1QBwa::-webkit-scrollbar-track {
    color: var(--white) !important;
    background-color: var(--adi-gray-dark) !important;
}

.MenuAccordion_listFirstLevelAccordions__1QBwa::-webkit-scrollbar-thumb {
    color: var(--white) !important;
    background-color: var(--adi-gray-dark) !important;
}

.MenuAccordion_listSecondLevelAccordions__3NLl3 {
    font-size: 14px;
}

.MenuAccordion_listSecondLevelAccordions__3NLl3 > li {
    color: var(--white) !important;
    border: none;
    padding: 0.2rem 0.0rem;
    background-color: var(--adi-gray-700) !important;
    padding-left: 3rem !important;
}

.MenuAccordion_listSecondLevelAccordions__3NLl3 > li:hover {
    color: var(--black) !important;
    font-weight: bold;
    background-color: #cccccc !important;
}

.MenuAccordion_activeArrowIcon__225Ar {
    fill: var(--white);
}

/*.activeArrowIcon:hover {
    fill: var(--white);
}*/

.MenuAccordion_contattiUtili__3Jjlq{
    margin-left: -40px;
    cursor: pointer;
}

.MenuAccordion_contattiUtili__3Jjlq:hover{
    color: var(--black) !important;
    font-weight: bold;
    background-color: #cccccc !important;
    width: 44vh;
}

.MenuAccordion_disabledArrowIcon__2iBQA {
    fill: var(--black);
    opacity: 0.7;
}

.MenuAccordion_customBadge__38ow4 {
    width: 24px;
    height: 24px;
    color: var(--prymary) !important;
    background-color: #ccc !important;
    display: inline !important;
    border-radius: 16px;
}

.MenuAccordion_scroll__2xKmD {
    overflow: auto;
    overflow-x: hidden;
}
.MenuAccordion_scroll__2xKmD::-webkit-scrollbar {
    width: 12px;
}

.MenuAccordion_scroll__2xKmD::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}

.MenuAccordion_scroll__2xKmD::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.MenuAccordion_menuPosition__3vk5z{
    width: 95.4%;
    top: 0;
    position: fixed;
    z-index: 100;
    margin-left: -1px !important;
    min-height: 100%;
}

.MenuAccordion_bgInfoIcon__2v0QE{
    background-color: #FFFFFF;
}

@media (max-width: 992px) {
    .MenuAccordion_menuPosition__3vk5z{
        width: 95.4%;
        top: 0;
        position: fixed;
        z-index: 100;
        margin-left: -17px !important;
    }
}

.Home_backgroundMode__3ISDf {
    z-index: -1;
    opacity: 20%;
    pointer-events: none;
    background-color: #F6F7F9 !important;
}

.Home_title__1wLgc {
    color: var(--prymary);
    font-size: 18px;
    font-weight: 700;
}

.Home_containerPopoverTeamIcon__mjEpV {
    text-align: center !important;
}

.Home_teamIconConfermato__xPSzt {
    margin-left: 5% !important;
    padding-right: 20px !important;
}

.Home_teamIconNonConfermato__3Xox1 {
    margin-left: 10% !important;
}

.Home_filtriRapidi__3CdHv {
    background-color: var(--adi-gray-400);
}

.Home_homeContainer__1eWAk {
     min-height: calc(100vh - 9.7rem);
}

.Questionario_requiredFields__CqZM0 {
    color: var(--adi-red);
    font-weight: 500;
}

.Questionario_risposta__2lZkt:nth-child(odd) {
    background-color: #f2f2f2 !important;
}

.Questionario_descriptionPunteggio__3YO0Y {
    color: gray !important;
    text-align: justify;
}

.Questionario_punteggi__2zjIF {
    font-size: 12px;
    margin-top: -6px;
    color: black;
    font-style: italic;
    opacity: .5;
    margin-bottom: 6px;
}

.CompilaQuestionario_containerSezioneQuestionari__32vDi {
    margin-left: 5vw;
    margin-right: 5vw;
}

.CompilaQuestionario_descrizioneQuestionario__uYxeJ {
    text-align: center;
    font-weight: bold;
}

.CompilaQuestionario_requiredFields__2k2hd {
    color: var(--adi-red);
    font-weight: 500;
}

.SvuotaQuestionario_btnSvuotaQuestionationario__Pct-T {
    cursor: pointer;
}
.InformazioniBaseFaseRichiesta_aggiungiAccordion__2bqM3 {
    cursor: pointer;
    color: var(--prymary) !important;
}

.CompilaProposte_btnAnnullaStyle__3Me2O {
    width: 11vw !important;
    min-width: 130px !important;
    height: 2.5rem !important;
    font-size: 14px;
}

.CompilaProposte_rowTableGray__87Ll2 {
    background-color: lightgray;
  }
.CompilaProposte_rowTableWhite__3js69 {
    background-color: white;
  }
@media screen and (max-width: 992px) and (min-width: 475px) {

    .CompilaProposte_btnAnnullaStyle__3Me2O {
        width: 10vw !important;
    }
}




.PopupRichiestaDiModifica_modalBtnStyle__1usFA {
    width: 14vw !important;
    height: 2.5vw !important;
}

.PopupRichiestaDiModifica_modalGreen__2d8TJ {
    border: 0.1vw solid var(--prymary) !important;
    color: var(--prymary) !important;
    font-weight: bold;
}

.PopupRichiestaDiModifica_modalGreen__2d8TJ:active, .PopupRichiestaDiModifica_modalGreen__2d8TJ:hover, .PopupRichiestaDiModifica_modalGreen__2d8TJ:focus {
    background-color: var(--prymary) !important;
    color: #FFFFFF !important;
}

.PopupRichiestaDiModifica_buttonProseguiConsultazione__jpJ81 {
    background-color: var(--prymary) !important;
    color: #FFFFFF !important;
}

.PopupRichiestaDiModifica_backgroundGreen__p07d1 {
    background-color: var(--prymary) !important;
    color: #FFFFFF;
    font-size: 18px;
}

.PopupRichiestaDiModifica_availableCharsLabel__2L6rO {
    opacity: 0.7;
    font-size: 14px;
    text-align: right;
    position: absolute;
    right: 7px;
}

.PopupAutoAssegnazioneValutazione_modalBtnStyle__1Npz- {
    width: 14vw !important;
    height: 2.5vw !important;
}

.StatoValutazioneEComponentiDelTeam_breadcrumbBorder__5Wk4s {
    border-bottom: 1px solid rgb(200, 200, 200);
}

.StatoValutazioneEComponentiDelTeam_customContainer__2_KtR {
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}

.StatoValutazioneEComponentiDelTeam_customModalBody__ziO6T {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.StatoValutazioneEComponentiDelTeam_customModalContainer__2pPnC {
    width: 60vh !important;
    overflow: hidden !important;
}

.StatoValutazioneEComponentiDelTeam_title__36bQ0 {
    color: var(--prymary) !important;
    font-size: 14px;
    font-weight: 800;
}

.StatoValutazioneEComponentiDelTeam_menuSticky__1X5Xy {
    position: -webkit-sticky;
    position: sticky;
    top: 36px;
    z-index: 4;
    background-color: var(--white);
}

.SchedaProfessionista_boxScheda__1Y6sP {
    border: 1px solid var(--prymary);
    background-color: var(--prymary);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,0.40);
    border-radius: 10px!important;
    color: var(--white);
    min-height: 300px;

}

.SchedaProfessionista_boxScheda__1Y6sP:after {
    content: "";
    display: block;
}

.SchedaProfessionista_roundedIcon__2o_2Q {
    border: 1px solid var(--white);
    width: 50px;
    height: 50px;
}

.SchedaProfessionista_labelAttivaProfessionista__Ku_U7 {
    color: var(--white);
    font-weight: 500;
    text-decoration: underline;
    margin-top: 16px
}

.SchedaProfessionista_dot__3eJVL {
    width: 8px;
    height: 8px;
    background-image: radial-gradient(circle, var(--white) 10px, transparent 11px);
    border-radius: 50%;
}

.SchedaProfessionista_containerStatoUtente__31ADD {
    border: 1px solid var(--prymary);
    border-radius: 25px;
    width: 100px;
    background-color: var(--white);
    color: var(--black);
    position: relative;
    margin-left: 104px;
    margin-top: 10px;
}

.SchedaProfessionista_onlineDot__FekFW:before {
    content: "\A";
    width: 15px;
    height: 15px;
    background: var(--prymary);
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: -2px;
}

.SchedaProfessionista_eliminaProfessionista__224rl {
    background: none;
    color: red !important;
    fill: red!important;
}
.SchedaProfessionista_eliminaProfessionistaDisabilitato__KfDOw {
    background: none;
    color: dimgrey !important;
    fill: dimgray!important;
    cursor: default;
    pointer-events: none;
}
.SchedaProfessionista_eliminaProfessionista__224rl:hover {
    text-decoration: underline;
}

.SchedaProfessionista_popoverElimina__1XLHr{
    z-index: 8;
}

.SchedaProfessionista_schedaSpanLi__226cl {
    color: var(--prymary) !important;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    height: 40px;
}

.SchedaProfessionista_schedaDropdownItem__GjXRD {
    padding-top: 15px !important;
    padding-bottom: 30px !important;
}

.SchedaProfessionista_schedaDropdownItem__GjXRD:active {
    background: #E6ECF2 !important;
}


@media screen and (max-width: 768px) {
    .SchedaProfessionista_boxScheda__1Y6sP {
        margin-bottom: 10%;
    }
}

.ModaleAttivazioneFiguraProfessionale_requiredField__2Ibpm {
    font-size: 14px;
    color: var(--adi-red);
    background: #fff;
    font-style: italic;
    padding: 0 2px!important;
    font-weight: 300;
}

.ModaleAttivazioneFiguraProfessionale_bottomPlaceholder__26Wgg {
    opacity: 0.5;
    font-size: 14px;
}

.AttivazioneFiguraProfessionale_aggiungiFiguraRichiesta__1Ot1d{
    fill: var(--prymary);
    color: var(--prymary);
}
.AttivazioneFiguraProfessionale_disabledAggiungiFiguraRichiesta__H6cT9{
    fill: grey;
    color: grey;
}
.AttivazioneFiguraProfessionale_eliminaFiguraRichiesta__2q5gh{
   fill: var(--prymary);
    margin-left: 6px;
    margin-bottom: 7px;
}

.AttivazioneFiguraProfessionale_eliminaFiguraRichiestaDisabilitato__1frrR{
    background: none;
    color: grey;
    fill: grey;
    cursor: default;
    pointer-events: none;
    vertical-align: baseline;
    margin-left: 6px;
    margin-bottom: 7px;
}

.AttivazioneFiguraProfessionale_popoverElimina__2CMga{
    z-index: 8;
}

.AttivazioneFiguraProfessionale_title__HzWhA{
    color: var(--prymary) !important;
    font-size: 14px;
    font-weight: 800;
}

.AttivazioneFiguraProfessionale_bottomPlaceholder__38oZu {
    font-size: 14px;
    color: var(--black);
    font-weight: 400;
}

.AttivazioneFiguraProfessionale_attivaProfessionaleContainer__ttO7q{
    min-height: 768px;
}
.TabellaStatoValutazione_azTableCellTwo__2NAAX {
    /*border: none !important;*/
    font-size: 14px;
    text-align: center;
    height: 40px;
    padding: 5px;
    min-width: 108px;

}

.TabellaStatoValutazione_title__fqbYK {
    color: var(--prymary) !important;
    font-size: 14px;
    font-weight: 800;
}

.TabellaStatoValutazione_tableContainer__1x_Br {
    border: 1px solid #176A65 !important;
    border-bottom: 1px solid #176A65 !important;
    border-radius: 10px!important;
}

.TabellaStatoValutazione_link__2oCgD {
    font-size: 14px;
    line-height: 20px;
    color: var(--prymary) !important;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline!important;
}

.TabellaStatoValutazione_popoverStoricoProfessionista__2abNZ {
    width: initial !important;
}

.CompilaRichiestaCampiObbligatori_customButton__2x0Nd {
    margin: 0 !important;
    width: 100%;
}

.CompilaRichiestaCampiObbligatori_rowTableGray__3rlgR {
    background-color: lightgray;
}

.CompilaRichiestaCampiObbligatori_rowTableWhite__1udXU {
    background-color: white;
}








.aggiungiAccordion {
    cursor: pointer;
    font-weight: bold;
    color: var(--prymary) !important;
}

.ValutazioneSistemaRespiratorio_box__3Vn8T {
    border: 1px solid;
    border-color: black;
}

.ValutazioneDellaCute_box__3IFKg{
    border: 1px solid;
    border-color: black;
}




.AnagrafePaziente_h6__I1nxF {
    border-bottom: 5px solid #999;
}

.AnagrafePaziente_fieldContainer__1SnCc {
    height: 5rem;
}

.AnagrafePaziente_mandatoryColor__BQ_yF {
    color: var(--adi-red)
}

form {
    width: 300px;
}


.AnagrafePaziente_intl-tel-input__ab93k {
    display: table-cell;
}

.AnagrafePaziente_intl-tel-input__ab93k .AnagrafePaziente_selected-flag__1H2AT {
    z-index: 4;
}

.AnagrafePaziente_intl-tel-input__ab93k .AnagrafePaziente_country-list__2DHAC {
    z-index: 5;
}

.AnagrafePaziente_input-group__3Xvrr .AnagrafePaziente_intl-tel-input__ab93k .AnagrafePaziente_form-control__349qz {
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
}


.DatiGenerali_invalidInput__1wsY3 {
    font-size: 14px;
    color: var(--adi-red);
    position: absolute;
}




.MedicoDiBase_requiredText__2ulay {
    font-weight: bold;
}

.MedicoDiBase_requiredText__2ulay:after {
    content: "*";
    color: var(--adi-red);
}

.MedicoDiBase_labelField__19NH4 {
    font-weight: bold;
}

.MedicoDiBase_invalidInput__2KILZ {
    font-size: 14px;
    color: var(--adi-red);
    position: absolute;
}


.ResidenzaEDomicilio_requiredText__2GTZo {
    font-size: 14px;
    color: var(--adi-red);
}

.SettingProvenienza_labelField__-ed9n {
    font-weight: bold;
}

.AnagrafeRichiedente_modalHeader__1OIfQ {
    color: white;
    font-weight: bold;
    padding: 0.8vw !important;
    font-size: 1.1vw !important;
}





.AttivitaStrumentaliDellaVitaQuotidiana_box__3F-ih {
    border: 1px solid black;
}


.CopingTolleranzaStress_box__39bwV{
    border: 1px solid;
    border-color: black;
}

.Donna_box__16Apb{
    border: 1px solid;
    border-color: black;
}
.GradoDiMobilita_box__3UKMB{
    border: 1px solid;
    border-color: black;
}
.Idratazione_box__35H6R{
    border: 1px solid;
    border-color: black;
}



.NecessitaDiAssistenzaSanitaria_box__1_FyT{
    border: 1px solid;
    border-color: black;
}
.Respiro_box__2baYQ {
    border: 1px solid;
    border-color: black;
}


.SensorioDolore_box__1ifdI{
    border: 1px solid;
    border-color: black;
}
.SensorioEquilibrio_box__12IDP{
    border: 1px solid;
    border-color: black;
}


.StatoCognitivo_box__1euO0{
    border: 1px solid;
    border-color: black;
}

.StatoFunzionale_box__15nqA {
    border: 1px solid black;
}

.StatoNutrizionale_box__2YUNG{
    border: 1px solid;
    border-color: black;
}
.Uomo_box__1yrv_{
    border: 1px solid;
    border-color: black;
}






.NavBar_navContainer__WLyTi {
    background-color: var(--prymary)!important;
    height: 60px;
    color: #19191A;
    padding: 0 30px 0 30px;
}
.NavBar_navLink__30eQP{
    color: white;
}

.NavBar_hamburgerNav__gpby- {
    background-color: var(--prymary) !important;
    padding: 0.5px 30px !important;
  }

.NavBar_icohamburgerNav__2nUCq{
    fill: var(--white) !important;
}
.NavBar_icohamburgerNav__2nUCq:hover{
    background-color: var(--white) !important;
    fill: var(--prymary) !important;
}

  .NavBar_navbarCollapse__3K0L9 {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    margin-top: -10px;
    margin-bottom: -20px;
  }
  

.NavBar_styleSecondaFascia__Z19Za {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    background-color: var(--prymary);
    height: 58px;
    color: #19191A;
    font-size: 33px;
}

.NavBar_navInfo__14rU1 {
    flex-grow: 8;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


.NavBar_navCustom__EjUs8 {
    padding: 0;
}

.NavBar_labelCustom__1T0oB {
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: none;
} 

.NavBar_liPagina__e1mXZ {
    color: var(--white);
    background: var(--prymary);
    display: block;
    float: left;
    padding: 7px 23px;
    border-radius: 10px;
    position: relative;
    text-decoration: none;
    justify-content: left;
    text-align: center;
}

.NavBar_activePage__XwHE_ {
    background: var(--white);
    color: var(--prymary);
}

.NavBar_activePage__XwHE_:hover {
    background: var(--adi-primary-hover);
    cursor: pointer;
    color: var(--white);
}

.NavBar_stylePagineLi__1o73J:hover {
    background: var(--adi-primary-hover);
    cursor: pointer;
}

.NavBar_liLink__1wcSW {
    color: var(--prymary);
    height: 60px;
    display: block;
    float: left;
    padding-top: 10px;
    padding-left: 24px;
    padding-bottom: 24px;
    position: relative;
    text-decoration: none;
    justify-content: left;
}

.NavBar_liLink__1wcSW:hover {
    text-decoration: underline;
    background: var(--adi-primary-hover);
}

.NavBar_actionIcon__15MH8 {
    vertical-align: top;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 7.3px;
}

.NavBar_actionIcon__15MH8:hover {
    background: var(--adi-primary-hover);
    text-decoration: none !important;
}

.NavBar_caption__sMVO5 {
    display: block;
    font-size: 14px;
    color: var(--white);
    padding-top: 0.14rem;
}

.NavBar_caption__sMVO5:hover {
    color: black;
    text-decoration: none !important;
}

.NavBar_actionIconInBurgerMenu__gQ5yi {
    vertical-align: top;
    display: inline-block;
    cursor: pointer;
}

.NavBar_separatorVerticalBar__3qtok {
    border: 0.5px solid var(--adi-gray-dark);
    padding-top: 8px;
    padding-bottom: 15px;
}

.NavBar_filtroRicercaAvanzata__101il {
    width: 20% !important;
    height: 40px !important;
    vertical-align: text-top;
}

.NavBar_btnAnnullaStyle__hatLy {
    width: 15vw;
    min-width: 130px;
    height: 2.2rem;
    font-size: 14px;
    background-color: var(--white);
    border: none;
    color: var(--prymary);
    font-weight: bold;
}

.NavBar_searchButton__1pcm6 {
    float: right;
    margin-right: 2%;
}

/* 992px è lo schermo lg*/
@media screen and (max-width: 992px) and (min-width: 475px) {
    .NavBar_btnAnnullaStyle__hatLy {
        width: 25vw !important;
    }
}

.NavBar_nonLette__1oVza {
    float: right;
    margin-top: 5%;
    height: 20px;
    width: 20px;
    background-color: #FF7373;
    border-radius: 50%;
    font-size: 14px;
    color: white;
}

.NavBar_iconLink__1MbXS {
    text-decoration: none !important;
}

.NavBar_popoverNavBar__3a8rp{
    top: 107px!important;
}

@media (max-width: 1199px){
    .NavBar_liPagina__e1mXZ {
        padding: 10px 15px;
    }
}





.PreviewNotifica_previewNotifica__3tJ7F {
    border-radius: 10px;
    cursor: pointer;
}

.PreviewNotifica_previewNotifica__3tJ7F:hover {
    background-color: #eaf3f7;
}

.PreviewNotifica_previewNotificaLetta__2uFfv {
    background-color: #F2F2F2 !important;
}

.PreviewNotifica_previewNotificaLetta__2uFfv:hover {
    background-color: #EAF3F7 !important;
}

.PreviewNotifica_previewNotificaNonLetta__mtt8g {
    font-weight: bold;
}

.PreviewNotifica_titoloPreviewNotificaCentro__1sZTI {
    color: var(--prymary);
    text-decoration: underline;
    white-space: normal !important;
}

.PreviewNotifica_titoloPreviewNotifica__1zosm {
    color: var(--prymary);
    text-decoration: underline;
}

.PreviewNotifica_notificaNonLetta__3ECEv {
    font-weight: bold;
}

.PreviewNotifica_selectedPreview__10Tzl {
    background-color: var(--adi-gray-100) !important;
}

.PreviewNotifica_ellipsisText__278QG {
    white-space: nowrap;
    overflow-x: hidden !important;
    text-overflow: ellipsis !important; 
}

.PreviewNotifica_capitalizeLabel__24-ls {
    text-transform: capitalize;
}









.LivelloPrioritaRichiestaSub_invalidInput__18OTv {
    font-size: 14px;
    color: var(--adi-red);
    position: absolute;
}




.ProposteAttivitaSuggerimentiSottoAccordion_bottomPlaceholder__3UkPh {
    opacity: 0.5;
    font-size: 14px;
}


.ContainerPaginaCompila_rowTableGray__8aQTx {
    background-color: lightgray;
}

.ContainerPaginaCompila_rowTableWhite__3WuFn {
    background-color: white;
}

.TabellaDeleghe_azTableCellTwo__1Xi7t {
    /*border: none !important;*/
    font-size: 14px;
    text-align: center;
    height: 40px;
    padding: 5px;
    min-width: 108px;

}

.TabellaDeleghe_testoAzioni__3XEUG {
     font-size: 14px;
     line-height: 20px;
     color: var(--prymary) !important;
     cursor: pointer;
 }

.TabellaDeleghe_iconSpaces__1xXtr{
    margin-right: 10px;
}

.TabellaDeleghe_popoverStoricoProfessionista__3480g {
    width: initial !important;
}

.TabellaDeleghe_separator__1SWWt {
    background-color: var(--prymary);
    height: 4px;
    border-radius: 10px;
    margin: 0;
}

.GestioneDeleghe_breadcrumbBorder__1POZ_ {
    border-bottom: 1px solid rgb(200, 200, 200);
}

.GestioneDeleghe_customContainer__3nVNd {
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}

.GestioneDeleghe_customModalBody__2m7qE {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.GestioneDeleghe_customModalContainer__mVckH {
    width: 60vh !important;
    overflow: hidden !important;
}

.InserisciDelega_delegato__3gKfN{
    fill: var(--prymary);
    color: var(--prymary);
}

.InserisciDelega_disabled__2bK19{
    fill: grey;
    color: grey;
}




.ConsumoDiAlcool_box__1AZQI{
    border: 1px solid black;
}

.ConsumoDiTabacco_box__2uv98{
    border: 1px solid;
    border-color: black;
}


.Error_errorContainer__1ultu{
    margin-top: 10%;
    align-items: center;
    justify-items: center;
    text-align: center;
}



.ApparatoVascolarePeriferico_box__1LQuP{
    border: 1px solid;
    border-color: black;
}
.Capo_box__2dQzc{
    border: 1px solid;
    border-color: black;
}
.Collo_box__109mz{
    border: 1px solid;
    border-color: black;
}

.CuteEAnnessi_box__3SMd2{
    border: 1px solid;
    border-color: black;
}




.Occhi_box__2phfy{
    border: 1px solid;
    border-color: black;
}

.Orofaringe_box__1wqwd{
    border: 1px solid;
    border-color: black;
}

.SistemaLinfatico_box__3qoTe{
    border: 1px solid;
    border-color: black;
}
.Torace_box__13X6X{
    border: 1px solid;
    border-color: black;
}
.Urogenitale_box__ydRFi{
    border: 1px solid;
    border-color: black;
}

.Faq_contentFaq__5McG6 {
    color: gray !important;
    font-size: 14px;
    text-align: justify;
}

.TabellaRichiesteDaFirmare_linkSelezionaTutte__1XcVZ {
    color: var(--prymary);
    text-decoration: underline;
    font-size: 14px;
    font-weight: 800;
}
.TabellaRichieste_btnAnnullaStyle__uVR5R {
    width: 11vw !important;
    min-width: 130px !important;
    height: 2.5rem !important;
    font-size: 14px;
}

.TabellaRichieste_separator__1-zSx {
    background-color: var(--prymary);
    height: 4px;
    border-radius: 10px;
    margin: auto;
}
.TabellaRichieste_info__3u1er {
    text-transform:uppercase;
    color: var(--prymary);
    font-weight: 700;
}

.TabellaRichieste_container__2amzB {
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    min-height: calc(100vh - 15rem);
}

.FirmaFormalizzazioni_customContainer__3iHoB {
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    min-height: calc(100vh - 20rem);
}
.Button_testo__2bL9J {
    text-align: left;
}

.Modale_h5custom__BWP8w {
    line-height: 3;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    font-weight: 700;
}

.Modale_containerConsenso__3PrFg {
    text-align: center;
    border-left: 3px solid var(--prymary) !important;
    border-right: 3px solid var(--prymary) !important;
}

.Modale_containerOscura__1OdK9 {
    text-align: center;
    border-left: 3px solid #986f00 !important;
    border-right: 3px solid #986f00 !important;
}

.Modale_containerViola__29JJa {
    text-align: center;
    border-left: 3px solid #bf023d !important;
    border-right: 3px solid #bf023d !important;
}

.Modale_buttons__2OD2N {
    width: 100% !important;
}

.Modale_buttonAnnulla__TklHW {
    width: 50% !important;
    font-size: 14px !important;
}

.Modale_buttonOk__1sHjS {
    font-size: 14px !important;
    width: 50% !important;
}

.Modale_modalContentCustom__QZX1j {
    min-width: 500px;
    text-align: center !important;
}

.Modale_modalContainerCustom__3lNnb {
    width: 100% !important;
}

.Modale_modalBodyCustom__2BdkF {
    text-align: left !important;
    color: black !important;
}

.Modale_modalConsenso__1DPQT {
    height: 100%;
    border: 2px solid var(--prymary);
}

.Modale_modalHeader__1pLKJ {
    width: 100%;
    background-color: var(--prymary);
    color: white;
    border-radius: 0;
}

.Modale_modalHeader__1pLKJ h5 {
    width: 100%;
    background-color: var(--prymary);
    height: 50px;
    color: white;
    font-size: 14px;
    line-height: 3;
}

.Modale_modalBody__7Unzz {
    border-right: 3px solid var(--prymary);
    border-left: 3px solid var(--prymary);
}

.Modale_btnAnnulla__1slyE {
    margin-bottom: 9px;
    font-size: 12px;
    width: 50%;
    margin-right: 5px;
    border: 1px solid;
}

.Modale_btnConsenso__26Ro3 {
    width: 50%;
    margin-left: 5px;
    font-size: 14px;
    height: 60%;
}

.Modale_modalFooter__153uR {
    border-right: 3px solid var(--prymary);
    border-left: 3px solid var(--prymary);
    border-bottom: 3px solid var(--prymary);
    border-radius: 0;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.Modale_triangle-down-modal-consenso__22HdC {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 25px solid var(--prymary);
    margin: 0 auto;
}

/* Modale oro oscura doc*/

.Modale_modalOscura__bpcA_ {
    height: 100%;
    border: 2px solid #986f00;
}

.Modale_modalHeaderOscura__1VRj9 {
    width: 100%;
    background-color: #986f00;
    height: 50px;
    color: white;
    border-radius: 0;
}

.Modale_modalHeaderOscura__1VRj9 h5 {
    background-color: #986f00;
    width: 100%;
    height: 50px;
    color: white;
    font-size: 14px;
    line-height: 3;
}

.Modale_modalBodyOscura__3vLKC {
    border-right: 3px solid #986f00;
    border-left: 3px solid #986f00;
}

.Modale_triangle-down-modal-oscura__1m2Rv {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 25px solid #986f00;
    margin: 0 auto;
}

.Modale_modalFooterOscura__2OdtA {
    border-right: 3px solid #986f00;
    border-left: 3px solid #986f00;
    border-bottom: 3px solid #986f00;
    border-radius: 0;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.Modale_btnConsensoOscura__LQtei {
    width: 50%;
    margin-left: 5px;
    font-size: 14px;
    height: 60%;
    background-color: #986f00;
    border: 0px;
    outline: 0;
}

.Modale_btnConsensoOscura__LQtei:hover {
    width: 50%;
    margin-left: 5px;
    font-size: 14px;
    height: 60%;
    background-color: #986f00;
    border: 0px;
}

.Modale_btnConsensoOscura__LQtei:active {
    width: 50%;
    margin-left: 5px;
    font-size: 14px;
    height: 60%;
    background-color: #986f00 !important;
    border: 1px solid #986f00 !important;
}

.Modale_btnConsensoOscura__LQtei:focus {
    outline: 0;
    box-shadow: none;
    -webkit-box-shadow: none !important;
    margin: auto;
    background-color: #986f00;
    box-shadow: inset 0 0 0 2px #986f00;
    background-color: #986f00 !important;
    color: white !important;
    border: 1px solid #986f00;
}

.Modale_btnAnnullaOscura__1KBxA {
    margin-bottom: 9px;
    font-size: 12px;
    width: 50%;
    margin-right: 5px;
    border: 1px solid;
    box-shadow: inset 0 0 0 2px #986f00;
    color: #986f00;
}

.Modale_btnAnnullaOscura__1KBxA:hover {
    margin-bottom: 9px;
    font-size: 12px;
    width: 50%;
    margin-right: 5px;
    border: 1px solid #986f00;
    box-shadow: inset 0 0 0 2px #986f00;
    color: #986f00;
}

.Modale_btnAnnullaOscura__1KBxA:focus {
    outline: 0;
    box-shadow: none;
    -webkit-box-shadow: none !important;
    margin-bottom: 9px;
    font-size: 12px;
    width: 50%;
    margin-right: 5px;
    border: 1px solid #986f00;
    box-shadow: inset 0 0 0 2px #986f00;
    color: #986f00 !important;
}

/* Modale VIOLA*/

.Modale_modalViola__13DM0 {
    height: 100%;
    border: 2px solid #bf023d;
}

.Modale_modalHeaderViola__gRJ8y {
    width: 100%;
    background-color: #bf023d;
    height: 50px;
    color: white;
    border-radius: 0;
}

.Modale_modalHeaderViola__gRJ8y h5 {
    background-color: #bf023d;
    width: 100%;
    height: 50px;
    color: white;
    font-size: 17px;
    line-height: 3;
}

.Modale_modalBodyViola__2cQg0 {
    border-right: 3px solid #bf023d;
    border-left: 3px solid #bf023d;
}

.Modale_triangle-down-modal-viola__1lACZ {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 25px solid #bf023d;
    margin: 0 auto;
}

.Modale_modalFooterViola__1q0QQ {
    border-right: 3px solid #bf023d;
    border-left: 3px solid #bf023d;
    border-bottom: 3px solid #bf023d;
    border-radius: 0;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.Modale_btnAnnullaViola__3Z-jb {
    margin-bottom: 9px;
    font-size: 12px;
    width: 50%;
    margin-right: 5px;
    border: 1px solid;
    box-shadow: inset 0 0 0 2px #bf023d;
    color: #bf023d;
}

.Modale_btnAnnullaViola__3Z-jb:hover {
    margin-bottom: 9px;
    font-size: 12px;
    width: 50%;
    margin-right: 5px;
    border: 1px solid #bf023d;
    box-shadow: inset 0 0 0 2px #bf023d;
    color: #bf023d;
}

.Modale_btnAnnullaViola__3Z-jb:focus {
    outline: 0;
    box-shadow: none;
    -webkit-box-shadow: none !important;
    margin-bottom: 9px;
    font-size: 12px;
    width: 50%;
    margin-right: 5px;
    border: 1px solid #bf023d;
    box-shadow: inset 0 0 0 2px #bf023d;
    color: #bf023d !important;
}

.Modale_btnConsensoViola__11xVx {
    width: 50%;
    margin-left: 5px;
    font-size: 14px;
    height: 60%;
    background-color: #bf023d;
    border: 0px;
    color: white;
}

.Modale_btnConsensoViola__11xVx:hover {
    width: 50%;
    margin-left: 5px;
    font-size: 14px;
    height: 60%;
    background-color: #bf023d;
    border: none;
    color: white;
    box-shadow: var(--adi-gray-dark);
}

.Modale_btnConsensoViola__11xVx:active {
    margin: auto;
    color: white;
    outline: 0;
}

.Modale_btnConsensoViola__11xVx:focus {
    margin: auto;
    background-color: #bf023d;
    outline: 0;
    box-shadow: none;
    -webkit-box-shadow: none !important;
}

/* PULSANTI CSS */

.Modale_btnGreenOutline__2AS5d {
    
    background-color: var(--white);
    border: 3px solid var(--prymary);
    color: var(--prymary) !important;
    box-shadow: none;
}

.Modale_btnGreenOutline__2AS5d:hover {
    
    background-color: #deecf2;
    border: 3px solid #0e3f3b;
    color: #0e3f3b !important;
    box-shadow: none;
}

.Modale_btnGreenOutline__2AS5d:active {
    
    background-color: #deecf2;
    border: 3px solid #0e3f3b;
    color: #084662 !important;
    box-shadow: none;
}

.Modale_btnGreenOutline__2AS5d:focus {
    
    background-color: #deecf2;
    border: 3px solid #0e3f3b;
    color: #0e3f3b !important;
    box-shadow: none;
    -webkit-box-shadow: none !important;
}

.Modale_btnGreenOutline__2AS5d:disabled {
    
    background-color: var(--white);
    border: #d6d6d6;
    color: var(--adi-gray-dark) !important;
}

/* BTN PERICOLO VIOLA */
.Modale_btnViola__12SHg {
    
    background-color: #bf023d !important;
    color: var(--white) !important;
    border: 1px solid #bf023d !important;
    box-shadow: none;
}

.Modale_btnViola__12SHg:hover {
    
    background-color: #9f0636 !important;
    color: var(--white) !important;
    border: 1px solid #9f0636 !important;
    box-shadow: none;
}

.Modale_btnViola__12SHg:active {
    
    background-color: #890a32 !important;
    color: var(--white) !important;
    border: 1px solid #890a32 !important;
}

.Modale_btnViola__12SHg:focus {
    
    background-color: #bf023d !important;
    color: var(--white) !important;
    border: 1px solid #890a32 !important;
    outline: 0;
    box-shadow: none;
    -webkit-box-shadow: none !important;
}

.Modale_btnViola__12SHg:disabled {
    
    background-color: #d6d6d6;
    color: var(--white) !important;
    border: #d6d6d6;
}

/* BTN PERICOLO VIOLA OUTLINE */
.Modale_btnOutlineViola__2fkqM {
    
    background-color: var(--white);
    border: 3px solid #bf023d;
    color: #bf023d !important;
    box-shadow: none;
}

.Modale_btnOutlineViola__2fkqM:hover {
    
    background-color: #f8eef1;
    border: 3px solid #9f0636;
    color: #9f0636 !important;
    box-shadow: none;
}

.Modale_btnOutlineViola__2fkqM:active {
    
    background-color: #f6e2e8;
    border: 3px solid #890a32;
    color: #890a32 !important;
}

.Modale_btnOutlineViola__2fkqM:focus {
    
    background-color: #f8eef1;
    border: 3px solid #9f0636;
    color: #9f0636 !important;
    box-shadow: none;
    -webkit-box-shadow: none !important;
}

.Modale_btnOutlineViola__2fkqM:disabled {
    
    background-color: var(--white);
    border: #d6d6d6;
    color: var(--adi-gray-dark) !important;
}

/* BTN WARNING GIALLO */

.Modale_btnWarning__2y7K- {
    
    background-color: #986f00 !important;
    color: var(--white) !important;
    border: 1px solid #986f00 !important;
    box-shadow: none;
}

.Modale_btnWarning__2y7K-:hover {
    
    background-color: #886300 !important;
    color: var(--white) !important;
    border: 1px solid #886300 !important;
    box-shadow: none;
}

.Modale_btnWarning__2y7K-:active {
    
    background-color: #6f5100 !important;
    color: var(--white) !important;
    border: 1px solid #6f5100 !important;
    box-shadow: none;
}

.Modale_btnWarning__2y7K-:focus {
    
    background-color: #986f00 !important;
    color: var(--white) !important;
    border: 1px solid #6f5100 !important;
    outline: 0;
    box-shadow: none;
    -webkit-box-shadow: none !important;
}

.Modale_btnWarning__2y7K-:disabled {
    
    background-color: #d6d6d6;
    color: var(--white);
    border: #d6d6d6;
}

/* BTN WARNING OUTLINE */
.Modale_btnWarningOutline__bJKSt {
    
    background-color: var(--white);
    border: 3px solid #986f00;
    color: #986f00 !important;
    box-shadow: none;
}

.Modale_btnWarningOutline__bJKSt:hover {
    
    background-color: #f2ecde;
    border: 3px solid #886300;
    color: #886300 !important;
    box-shadow: none;
}

.Modale_btnWarningOutline__bJKSt:active {
    
    background-color: #ede3c9;
    border: 3px solid #6f5100;
    color: #6f5100 !important;
}

.Modale_btnWarningOutline__bJKSt:focus {
    
    background-color: #f2ecde;
    border: 3px solid #886300;
    color: #886300 !important;
    box-shadow: none;
    -webkit-box-shadow: none !important;
}

.Modale_btnWarningOutline__bJKSt:disabled {
    
    background-color: var(--white);
    border: #d6d6d6;
    color: var(--adi-gray-dark);
}

.Select_struttura__2NJvI {
    border: 3px solid var(--prymary);
    font-size: 14px;
    background: white !important;
    outline: 0;
    height: 2.5rem !important;
    width: 100%;
    font-weight: normal;
    color: var(--prymary);
}

.Select_struttura__2NJvI:disabled {
    border: 3px solid var(--prymary);
    font-size: 14px;
    background: white !important;
    outline: 0;
    height: 2.5rem !important;
    width: 100%;
    font-weight: normal;
    color: var(--prymary);
    opacity: 0.5 !important;
}


.AnnullamentoRichiesta_modalBtnStyle__1uSaD {
    width: 9vw !important;
    height: 2vw !important;
    font-weight: bold;
}

.AnnullamentoRichiesta_modalBtnConfermaAnnullamento__1Ac8G {
    width: 15vw !important;
}

.AnnullamentoRichiesta_modalBtnStyle__1uSaD:active, .AnnullamentoRichiesta_modalBtnStyle__1uSaD:hover, .AnnullamentoRichiesta_modalBtnStyle__1uSaD:focus {
    color: white !important;
}

.AnnullamentoRichiesta_modalBtnStyle__1uSaD:disabled {
    color: #dddddd !important;
}

.AnnullamentoRichiesta_modalOrange__33Z5X {
    border: 0.1vw solid var(--adi-orange) !important;
    color: var(--adi-orange) !important;
}

.AnnullamentoRichiesta_modalGreen__2mr9A {
    border: 0.1vw solid var(--prymary) !important;
    color: var(--prymary) !important;
}

.AnnullamentoRichiesta_modalBlue__1Zo2G {
    color: var(--prymary) !important;
}

.AnnullamentoRichiesta_modalOrange__33Z5X:active, .AnnullamentoRichiesta_modalOrange__33Z5X:hover, .AnnullamentoRichiesta_modalOrange__33Z5X:focus {
    background-color: var(--adi-orange) !important;
}

.AnnullamentoRichiesta_modalGreen__2mr9A:active, .AnnullamentoRichiesta_modalGreen__2mr9A:hover, .AnnullamentoRichiesta_modalGreen__2mr9A:focus {
    background-color: var(--prymary) !important;
}

.AnnullamentoRichiesta_modalHeader__2fc5e {
    color: white;
    font-weight: bold;
    padding: 0.8vw !important;
    font-size: 14px !important;
}

.AnnullamentoRichiesta_modalBody__2AaTv {
    vertical-align: middle !important;
    text-align: center !important;
    white-space: pre-wrap;
}

.AnnullamentoRichiesta_modalContent__ww5Xk {
    height: 24vw !important;
}

.AnnullamentoRichiesta_fontSizeText__1a575 {
    font-size: 14px !important;
}

.AnnullamentoRichiesta_backgroundOrange__3BXGQ {
    background-color: var(--adi-orange) !important;
}

.AnnullamentoRichiesta_backgroundGreen__Q6Ruc {
    background-color: var(--prymary) !important;
}

.AnnullamentoRichiesta_backgroundBlue__1mksc {
    background-color: var(--prymary) !important;
}

.AnnullamentoRichiesta_inputLabel__3kVTe {
    color: black;
}

.AnnullamentoRichiesta_infoInput__3CIo5 {
    font-size: 0.7vw !important;
}

.AnnullamentoRichiesta_campoObbligatorio__jYX4m {
    color: var(--adi-red)
}

.AnnullamentoRichiesta_closeAnnullaRichiesta__1dyQ2 {
    position: absolute;
    right: 10px;
    padding-right: 1vw;
    cursor: pointer;
}

.AnnullamentoRichiesta_modalDialog__14HHl {
    max-width: 30vw !important;
    height: 100% !important;
}

.AnnullamentoRichiesta_modalFooter__OpptR {
    padding-bottom: 1.6vw !important;
}
.FiltroRichieste_layout__3L1Jx {
  background-color: #d1e5ee;
  align-items: start;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 1%;
}

.FiltroRichieste_filterRow__2JI4U {
  display: flex;
  align-items: center;
  margin-left: -16px !important;
}

.FiltroRichieste_btnAnnullaStyle__1l0Gm {
  width: 11vw !important;
  min-width: 130px !important;
  height: 2.5rem !important;
  font-size: 14px;
}

/* 992px è lo schermo lg*/
@media screen and (max-width: 992px) and (min-width: 475px) {
  .FiltroRichieste_btnAnnullaStyle__1l0Gm {
    width: 25vw !important;
  }
}

.FiltroRichieste_annullaBtn__22A3c {
  max-height: 45px;
  margin-left: 1%;
  width: auto;
  font-size: 14px;
}

.FiltroRichieste_addSpace__pUfYi {
  margin-left: 25px;
  height: 0;
}
.FiltroRichieste_modal-backdrop__39xpp.FiltroRichieste_show__G1mLB {
  opacity: 0.8;
}

@media screen and (min-width: 992px) and (max-width: 1300px) {
  .FiltroRichieste_addSpace__pUfYi {
    margin-left: 65px;
  }
}

.FiltroRichieste_bodyModal__1ief9 {
  padding-bottom: 5.6vw !important;
}
.FiltroRapidoRichieste_headerTitle__11uBC {
    color: black;
    font-weight: bold;
    padding-left: 0 !important;
    cursor: pointer;
    padding-bottom: 0 !important;
}

.FiltroRapidoRichieste_radioCheckboxGroup__3BFUf {
    color: var(--prymary);
    font-weight: bold;
}

.FiltroRapidoRichieste_customCheckboxLabel__2rjax {
    color: #176A65 !important;
    font-weight: bold !important;
    font-size: 12px !important;
}

.GestionePassword_foregroundMode__mXSup {
    background-color: white;
}

.GestionePassword_required__2L-WA:after {
    content: "*";
    color: #BF023D;
} 
.GestionePassword_infoText__2enm2 {
    font-size: small;
}

.GestionePassword_modalHeader__1IQwS {
    color: white;
    font-weight: bold;
    padding: 0.8vw !important;
    font-size: 1.1vw !important;
}

.ModificaDataDimissioneProgrammata_inputLabel__3oG9u {
    color: black;
    margin-bottom: 0 !important;
}

.ModificaDataDimissioneProgrammata_infoInput__2Udri {
    font-size: 0.7vw !important;
}

.ModificaDataDimissioneProgrammata_campoObbligatorio__3knrA {
    color: var(--adi-red)
}

.ModificaDataDimissioneProgrammata_required__1Yq2D:after {
    content: "*";
    color: #BF023D;
} 
.RicercaAssistiti_foregroundMode__2GeMi {
    background-color: white;
}

.RicercaAssistiti_required__2BBZd:after {
    content: "*";
    color: #BF023D;
} 

.RicercaAssistiti_labelRisultati__23vqE {
    color: var(--prymary);
    font-weight: 700;
    font-size: 14px;
}





.SubentroRichiesta_inputLabel__HJoL8 {
    color: black;
    margin-bottom: 0 !important;
}

.SubentroRichiesta_infoInput__aL2TK {
    font-size: 0.7vw !important;
}

.SubentroRichiesta_campoObbligatorio__3W4xP {
    color: var(--adi-red)
}

.SubentroRichiesta_closeAnnullaRichiesta__2M4H_ {
    position: absolute;
    right: 10px;
    padding-right: 1vw;
    cursor: pointer;
}


.AderenzaAllaTerapia_box__3Td5U{
    border: 1px solid;
    border-color: black;
}
.AltriTrattamenti_box__2ZMhy {
    border: 1px solid;
    border-color: black;
}










.IntegratoriEFitoterapici_box__Simli {
    border: 1px solid;
    border-color: black;
}


.TrattamentiLesioniTrofiche_box__1hZ3F {
    border: 1px solid black;
}

.InformazioniBaseFaseValutazione_aggiungiAccordion__1wCWy {
    cursor: pointer;
    color: var(--prymary) !important;
}



.InserimentoRichiesta_customBorderLeft__1gVHl {
    border-left: 2px solid var(--prymary);
    height: 70px;
}

.InserimentoRichiesta_customMarginTop__3syxI {
    margin-top: 0;
}

@media screen and (max-width: 780px) {
    .InserimentoRichiesta_customMarginTop__3syxI {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.CentroNotifiche_borderStyle__2A8G_ {
    border-top: 3px solid var(--prymary) !important;
}

.CentroNotifiche_textSearch__3PsPf{
    color: #1d1d1b;
    font-weight: bold;
}

.CentroNotifiche_sectionScroll__k8mXZ {
    overflow-y: auto;
    overflow-x: hidden;
}

.CentroNotifiche_maxHeightContent__33HPV {
    overflow-y: auto;
    height: 100% !important;
}

.CentroNotifiche_buttonIcons__1hPGE {
    background-color: #F2F2F2 !important;
    border-color: #F2F2F2 !important;
    border-radius: 10px !important;
}

.CentroNotifiche_buttonIconSearch__1_7L1 {
    background-color: #F2F2F2 !important;
    border-color: #F2F2F2 !important;
    border-radius: 10px !important;
}

.CentroNotifiche_buttonIconSearch__1_7L1:hover {
    background-color: var(--prymary) !important;
    border-color: #F2F2F2 !important;
    border-radius: 10px !important;
}

.CentroNotifiche_cerca__1zkr_{
    color: var(--prymary) !important;
}
.CentroNotifiche_cerca__1zkr_:hover{
    color: #fff !important;
}

.CentroNotifiche_inputStyle__39FDh {
    padding: 1.5rem .5rem !important;
    background-color: #F2F2F2 !important;
    border-color: #F2F2F2 !important;
    border-radius: 10px !important;
}

.CentroNotifiche_breadcrumbCentroNotifiche__1qF_4 {
    /*margin: 0 0 0.5rem 0!important;*/
    /*padding: 0 !important;*/
    font-size: 0.8rem;
    color: var(--prymary) !important;
    font-weight: 700;
    margin-top: -8px !important;
}

.CentroNotifiche_breadcrumbCentroNotificheElem__FM-Bs {
    color: var(--prymary) !important;
    font-weight: 100;
}


.CentroNotifiche_breadcrumbSeparator__1Wy1l:before {
    content: '>' !important;
}

.CentroNotifiche_labelFiltri__1unX3 {
    cursor: pointer;
    margin: 0 !important;
    color: black;
}

.CentroNotifiche_labelFiltriSelected__3sZ6M {
    text-decoration: underline;
    color: var(--prymary);
}

.CentroNotifiche_labelNotifiche__2ufhg {
    width: 100% !important;
    border: 1px solid #D9DADB;
    border-radius: 10px;
}

.CentroNotifiche_checkboxNotifica__ujWCp {
    width: 15px;
    height: 15px;
}

.CentroNotifiche_checkboxSelezionaTutti__2vuTS {
    padding-left: 0.3vw;
}

.CentroNotifiche_title__2NKC9 {
    margin: 0 0 0 16px !important;
    width: 100% !important;
}

.CentroNotifiche_searchBarStyle__3S5Lk {
    margin: 0 0 40px 20px !important;
}

.CentroNotifiche_notificaInfoContainer__gNIYa {
    border-radius: 10px;
    background-color: #F2F2F2;
}


.Overlay_overlayBackground__C5733 {
    position: absolute;
    width: 94%;
    background-color: black;
    opacity: 0.5;
    z-index: 1060;
}

.Overlay_contentBackground__3V5pV {
    position: absolute;
    background-color: white;
    width: 94%;
    z-index: 1060;
}

.Overlay_content__3ZVnN {
    margin: 2%;
}

.PannelloConfigurazioneGestioneUtenteADI_container__2ny_a {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 13.8rem);
}

.PannelloConfigurazioneGestioneUtenteADI_requiredText__19oil {
    font-size: 14px;
    color: var(--adi-red);
}

.PannelloConfigurazioneGestioneUtenteADI_infoSticky__1KlDU {
    position: -webkit-sticky;
    position: sticky;
    top: -25px;
    padding-top: 1px;
    z-index: 5;
}


.PannelloConfigurazioneGestioneUtenteADI_aggiungiSetting__1BSDi {
    color: var(--prymary);
    cursor: pointer;
}

.PannelloConfigurazioneGestioneUtenteADI_btnDisabled__DKzAU {
    opacity: 0.5;
}

.FiltroUtente_layout__2q7zq {
    background-color: var(--white);
    align-items: start;
    border-color: var(--white);
    box-shadow: 0 3px 0 0 rgb(0 0 0 / 16%);
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;

    position: -webkit-sticky;

    position: sticky;
    top: 0px;
    z-index: 51;
}

.FiltroUtente_errorText__13QhO {
    font-size: 14px;
    color: var(--adi-red);
}


.PannelloConfigurazioneRicercaUtenteADI_show__3-4YI {
    display: block;
}

.PannelloConfigurazioneRicercaUtenteADI_fade__2KUQL {
    opacity: 0;
    transition: opacity 1.5s linear;
}

.PannelloConfigurazioneRicercaUtenteADI_fade__2KUQL.PannelloConfigurazioneRicercaUtenteADI_in__2rFFs {
    opacity: 1;
}




.SearchBar_highlight__35JUf {
    background-color: yellow !important;
}

.SearchBar_draggableContainer__3aeq5 {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 40%;
    height: 0;
    z-index: 99;
}

.SearchBar_containerPopoverSearchBar__1_tal {
    text-align: center !important;
    padding-top: 5px !important;
    margin-left: 15% !important;
}

.SearchBar_containerPopoverSearchBar__1_tal:after {
    content: "  ";
    white-space: pre;
}












.StoricoRichieste_backgroundMode__1LyqS {
    z-index: -1;
    opacity: 20%;
    pointer-events: none;
    background-color: #F6F7F9 !important;
}

.StoricoRichieste_apriAzione__1KVnQ {
    color: var(--prymary) !important;
    text-decoration: none !important;
    font-weight: bold !important;
    font-size: 0.8rem !important;
}

.StoricoRichieste_apriAzione__1KVnQ:hover {
    color: var(--adi-primary-hover) !important;
    text-decoration: underline !important;
}

.StoricoRichieste_dropdownAzioni__3C-SQ {
    position: static !important;
}

.StoricoRichieste_dropdownMenu__1LYjK, .StoricoRichieste_dropdownMenu__1LYjK:before {
    position: absolute !important;
    content: none !important;
    left: -10px !important;
    top: 0vw !important;
}

.StoricoRichieste_dropdownToggle__10kZY {
    border: none !important;
    background-color: transparent !important;
}

.StoricoRichieste_dropdownToggle__10kZY:focus {
    background-color: #bfd7e0 !important;
    border: none !important;
}

.StoricoRichieste_dropdownToggle__10kZY::after {
    content: none !important;
}

.StoricoRichieste_backgroundMode__1LyqS {
    z-index: -1;
    opacity: 20%;
    pointer-events: none;
    background-color: #F6F7F9 !important;
}

.TabellaStoricoRichieste_container__3lI90 {
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    min-height: calc(100vh - 20rem);
}

.TabellaStoricoRichieste_info__3mZPa {
    text-transform: uppercase;
    color: var(--prymary);
    font-weight: 700;
}

.TabellaStoricoRichieste_separator__3VWin {
    background-color: var(--prymary);
    height: 4px;
    border-radius: 10px;
    margin: 0;
}

.FiltroStoricoRichieste_layout__3kOOL {
    background-color: #d1e5ee;
    align-items: start;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 1%;
  }
  
  .FiltroStoricoRichieste_filterRow__2cJFC {
    display: flex;
    align-items: center;
    margin-left: -16px !important;
  }
  
  .FiltroStoricoRichieste_btnAnnullaStyle__GTKSX {
    width: 11vw !important;
    min-width: 130px !important;
    height: 2.5rem !important;
    font-size: 14px;
  }
  
  /* 992px è lo schermo lg*/
  @media screen and (max-width: 992px) and (min-width: 475px) {
    .FiltroStoricoRichieste_btnAnnullaStyle__GTKSX {
      width: 25vw !important;
    }
  }
  
  .FiltroStoricoRichieste_annullaBtn__2GNrJ {
    max-height: 45px;
    margin-left: 1%;
    width: auto;
    font-size: 14px;
  }
  
  .FiltroStoricoRichieste_addSpace__43WqH {
    margin-left: 25px;
    height: 0;
  }
  .FiltroStoricoRichieste_modal-backdrop__4xYsT.FiltroStoricoRichieste_show___XBsY {
    opacity: 0.8;
  }
  
  @media screen and (min-width: 992px) and (max-width: 1300px) {
    .FiltroStoricoRichieste_addSpace__43WqH {
      margin-left: 65px;
    }
  }
  
  .FiltroStoricoRichieste_bodyModal__3qfG5 {
    padding-bottom: 5.6vw !important;
  }

  .FiltroStoricoRichieste_cercaFiltraRichieste__3LZS2 {
    outline: none;
    width: auto;
    height: auto;
    padding: 5px 20px;
  }


.ScalaEQuestionari_linkQuestionario__3ilJ5 {
    cursor: pointer;
    text-decoration: underline;
    color: var(--prymary);
    font-weight: 700;
}

.ScalaEQuestionari_linkQuestionario__3ilJ5:hover {
    color: var(--adi-primary-hover);
}

.VisualizzaDettagliRichiestaStorico_customContainer__OMsEm {
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}

.VisualizzaDettagliRichiestaStorico_accordionDettaglio__AIWtG {
    margin-bottom: 16px;
}

.VisualizzaDettagliRichiestaStorico_accordionDettaglio__AIWtG:after {
    content: "";
    display: block;
}


.TornaSuBtn_topToBtm__3FzIG {
    position: relative;
}

.TornaSuBtn_iconStyle__hbVpG {
    position: fixed;
    bottom: 75px;
    z-index: 20;
    background-color: var(--prymary);
    border: 2px solid #fff;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    color: #fff;
    cursor: pointer;
    -webkit-animation: TornaSuBtn_movebtn__C5eby 3s ease-in-out infinite;
            animation: TornaSuBtn_movebtn__C5eby 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
    text-align: center;
    padding-top: 5px;
    right: 0;
}

.TornaSuBtn_containerTooltip__2Ao3F {
    text-align: center !important;
    background: var(--prymary) !important;
    color: var(--white) !important;
    padding: 10px !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

.ValutazioneBisogniPalliativiSub_invalidInput__1BOi6 {
    font-size: 14px;
    color: var(--adi-red);
    position: absolute;
}


.FormRiepilogo_customButton__FkJng {
    margin: 0 !important;
    width: 100%;
}




.NotAuthorized_notAuthContainer__2pkD_ {
    margin-top: 120px;
    align-items: center;
    justify-items: center;
    text-align: center;
}

.NotAuthorized_btnAccesso__CKaUQ {
    margin-top: 10vh;
    min-width: 180px;
    min-height: 45px;
}

.ServizioNonDisponibile_notAuthContainer__M6wFV{
    margin-top: 120px;
    align-items: center;
    justify-items: center;
    text-align: center;
}

