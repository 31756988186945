.notAuthContainer {
    margin-top: 120px;
    align-items: center;
    justify-items: center;
    text-align: center;
}

.btnAccesso {
    margin-top: 10vh;
    min-width: 180px;
    min-height: 45px;
}
