.requiredText {
    font-weight: bold;
}

.requiredText:after {
    content: "*";
    color: var(--adi-red);
}

.labelField {
    font-weight: bold;
}

.invalidInput {
    font-size: 14px;
    color: var(--adi-red);
    position: absolute;
}
