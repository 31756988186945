@import "variables.css";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url("https://fonts.googleapis.com/css?family=Exo+2");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
@import url("./global-css/modal.css");

label {
    color: #1D1D1B;
}

body {
  margin: 0;
  padding: 0;
  font-family: Tahoma!important;
}

::placeholder {
    color: var(--adi-gray-300) !important;
    opacity: 0.9 !important;
  }
  

input[type=date], input[type=datetime-local], input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], textarea,
.form-check [type=checkbox]+label, .form-check [type=radio]+label
{
    font-size: 14px!important;
}

.f-weight-700 {
    font-weight: 700;
}

.f-weight-600 {
    font-weight: 600;
}

.f-weight-500 {
    font-weight: 500;
}

.icon-green {
    fill: var(--prymary);
}

.table thead th,
.table td {
    padding-right: 0 !important;
    padding-left: 0 !important;
    color: #19191a!important;    
}

.stato-valutazione-table > tbody > tr > td:first-child,
.stato-valutazione-table > thead > tr > th:first-child 
{
    padding-left: 10px!important;

}

.stato-valutazione-table > thead > tr > th {
    color: black!important;
}

.stato-valutazione-table > tbody > tr > td
{
    border:none;
    border-radius: 5px!important;
    font-size: 14px!important;
    color: black!important;
    font-weight: 400!important;
}

.stato-valutazione-table {
    overflow-x: hidden!important;
    margin-bottom: 2px!important;
    color: var(--black)!important;
}

.storico-richieste-table > thead > tr > th,
.storico-richieste-table > tbody > tr > td {
    padding: 1em !important;
}

.firme-table > tbody > tr > td {
    padding: 0 !important;
}

.firme-table > thead th {
    padding-right: 1em !important;
}

.progress-spinner.progress-spinner-active:not(.progress-spinner-double) {
    border-color: var(--prymary) var(--prymary) #d4e9ff!important;
}

.btn-link {
    color: var(--prymary)!important;
    font-size: 14px!important;
}

.font {
    color: var(--adi-gray-dark);
    font-size: 14px;
    line-height: 20px;
}

strong, b {
    font-size: 14px;
    line-height: 20px;
}

.styleContainer {
    min-height: 90vh;
}

/* DATE PICKER CSS */
.it-date-datepicker.form-control {
    min-width: 0em !important;
}

.it-datepicker-wrapper .datepicker-calendar ::after {
    font-size: 0px !important;
    line-height: 0% !important;
    width: 0px !important;
    border-bottom: 20px solid #77c !important;
    border-left: 10px solid #f6f6f6 !important;
    border-right: 10px solid #f6f6f6 !important;
}


.it-datepicker-wrapper .datepicker-calendar {
    top: unset !important;
    left: 0 !important;
    text-align: center;
    min-width: 20em !important;
    border-top: 5px solid var(--prymary) !important;
}

.datepicker-calendar.default table.datepicker-grid td.selectable.focus span,
.datepicker-calendar.default table.datepicker-grid td.curDay span, 
.datepicker-calendar.default table.datepicker-grid td.curMonth span, 
.datepicker-calendar.default table.datepicker-grid td.curYear span {
    background: #176A65!important;
    color: white;
}

.datepicker-calendar.default table.datepicker-grid td.selectable.focus span {
    opacity: 0.5;
}

.datepicker-month-wrap {
    min-width: 15em !important;
}

.datepicker-grid {
    min-width: 15em !important;
}

.it-datepicker-wrapper .input-group:after {
    display: none !important;
}

.it-datepicker-wrapper .input-group .it-date-datepicker {
    font-size: 14px !important;
    padding-right: 0px !important;
    padding-bottom: 10px !important;
}

.it-datepicker-wrapper .input-group .datepicker-button {
    margin-right: 5px !important;
}

.it-datepicker-wrapper .input-group .datepicker-button:hover .icon {
    fill: var(--adi-primary-hover)!important;
}

/*END DATE PICKER CSS*/

/* TEXT CSS */
input[type=text], input[type=number], textarea {
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: normal !important;
}

input[type=text]:hover, input[type=number]:hover, textarea:hover, input.it-date-datepicker:hover {
    color: #886300 !important;
}

input[type=text]:active, input[type=number]:active, textarea:active, input.it-date-datepicker:active {
    color: #6f5100 !important;
}

input[type=text]:focus, input[type=number]:focus, textarea:focus, input.it-date-datepicker:focus {
    color: #886300 !important;
}

input[type=text]:disabled, input[type=number]:disabled, textarea:disabled, input.it-date-datepicker:disabled {
    color: var(--adi-gray-dark) !important;
    background-color: var(--adi-gray-600);
}

/* FINE TEXT CSS */

input[type=number]::-webkit-inner-spin-button {
    opacity: 1 !important;
}

/*CSS TIMEPICKER*/

#idHour1 {
    height: 35px;
    margin-top: 1px;
    border: transparent;
    font-size: 14px !important;
    width: 18px !important;
    padding: 0px !important;
    color: var(--prymary);
}

#idHour1::-webkit-input-placeholder { /* WebKit browsers */
    color:var(--prymary);
}

#idMinute1 {
    height: 35px;
    margin-top: 1px;
    border: transparent;
    font-size: 14px !important;
    width: 25px !important;
    padding: 0px !important;
    text-align: initial !important;
    color: var(--prymary);
}

#idMinute1::-webkit-input-placeholder { /* WebKit browsers */
    color: var(--prymary);
}

#idHour {
    height: 35px;
    margin-bottom: 2px;
    margin-left: 0px;
    border: none;
    font-size: 14px !important;
    width: 45px !important;
    padding: 0px !important;
    color: var(--prymary);
    background: transparent;
}

#idMinute {
    height: 35px;
    margin-bottom: 2px;
    border: none;
    font-size: 14px !important;
    width: 45px !important;
    padding: 0px !important;
    color: var(--prymary);
}

@media screen and (max-width: 767px) and (min-width: 500px) {
    #idHour {
        margin-top: 1.5px !important;
    }

    #idMinute {
        margin-top: 1.5px !important;
    }
}

@media screen and (max-width: 500px) and (min-width: 300px) {
    #idHour {
        margin-top: 2px !important;
    }

    #idMinute {
        margin-top: 2px !important;
    }
}

.timepicker-icon {
    fill:var(--prymary) !important;
}

.timepicker-icon:hover {
    fill: var(--prymary) !important;
}

/* FINE CSS TIMEPICKER*/

@media (min-width: 300px) {
    .navbar .dropdown-menu.show {
        animation: .3s forwards dropdownFadeIn !important;
        top: 53px !important;
    }

    .navbar .dropdown-menu {
        position: absolute !important;
        left: -10px !important;
        z-index: 1000;
        float: left;
        min-width: 10rem;
        padding: .5rem 0 0;
        margin: .125rem 0 0 !important;
        font-size: 1rem;
        color: #19191A;
        text-align: left;
        list-style: none;
        background-color: var(--white);
        background-clip: padding-box;
        border: 0 solid transparent;
        border-radius: 0 0 4px 4px;
        box-shadow: 0 20px 30px 5px rgba(0, 0, 0, .05);
    }

    .navbar .dropdown-toggle {
        display: block;
        justify-content: inherit;
    }
}

#dropdownMenuButton {
    min-width: 9rem;
}

@media screen and (max-width: 1199px) {
    #popoverInfoNavBar {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    #popoverInfoNavBar {
        background: var(--prymary) !important;
        color: var(--white) !important;
        padding: 5px 20px !important;
        font-size: 12px !important;
        border-radius: 10px !important;
        left: 1703px!important;
    }
}

@media screen and (max-width: 575px) and (min-width: 300px), (max-width: 1000px) {
    #dropdownMenuButton {
        height: 58px;
    }
}

#logoutMenu.show {
    width: 12.6rem;
    top: 10px !important;
    border: 3px solid var(--adi-primary-hover);
    margin-left: -70px !important;
}

@media screen and (max-height: 240px) {
    #logoutMenu.show {
        /* transform: translateY(90px) !important; */
        margin-top: 87px !important;
    }
}

/* freccettina del menu logout */
#logoutMenu.show::before {
    top: -11px !important;
    border-top: 3px solid var(--adi-primary-hover);
    border-left: 3px solid var(--adi-primary-hover);
    left: 90px !important;
}

.form-check [type=checkbox] + label {
    font-size: 14px;
    color: var(--adi-gray-dark);
}

.form-check [type=radio] + label {
    font-size: 14px;
    color: var(--adi-gray-dark);
}

.form-check [type=radio] + label::after,
.form-check [type=radio] + label::before,
.form-check [type=checkbox] + label::after {
    border: 2px solid var(--prymary) !important;
}

.form-check [type=radio]:checked + label::after,
.form-check [type=checkbox]:checked + label::after {
    background-color: var(--prymary) !important;
}

.required:after {
    content: "*";
    color: var(--adi-red);
}

.react-tiny-popover-container {
    left: calc(100% - 5px) !important;
}

.popover-helper {
    width: calc(25%) !important;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    transform-origin: 0 0;
    z-index: 99;
}

#popover-helper {
    margin-top: 6px;
    background: var(--prymary) !important;
    color: var(--white) !important;
    padding: 10px !important;
    font-size: 16px !important;
    border-radius: 5px !important;
    z-index: 99;
}

#popover-ellipsis {
    background: var(--prymary) !important;
    color: var(--white) !important;
    padding: 10px !important;
    font-size: 16px !important;
    text-align: center;
    border-radius: 5px !important;
    margin-top: 50px !important;
}

#popover-info {
    background: var(--prymary) !important;
    color: var(--white) !important;
    padding: 10px;
    font-size: 14px !important;
    border-radius: 5px !important;
    width: 85.4882%;
}

/* TITOLO CSS*/

h1 {
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 36px !important;
    color: var(--prymary);
}

/* END TITOLO CSS */


/* LIVELLI SOTTOTITOLI CSS */

h2 {
    font-size: 20px !important;
    line-height: 32px !important;
    font-weight: 700 !important;
    color: #1D1D1B !important;
}

h3 {
    font-size: 14px !important;
    font-weight: 700 !important;
    color: #1D1D1B !important;
}

/* END LIVELLI SOTTOTITOLI CSS */


/* LINK CSS */

a:link {
    font-size: 14px;
    line-height: 20px;
    color: var(--prymary);
}

a:hover {
    font-size: 14px;
    line-height: 20px;
    color: var(--adi-primary-hover);
}

a:active {
    font-size: 14px;
    line-height: 20px;
    color: #084662;
}

a:focus {
    font-size: 14px;
    line-height: 20px;
    color: var(--adi-primary-hover);
}

a:visited {
    font-size: 14px;
    line-height: 20px;
    color: var(--prymary);
}

/* END LINK CSS */

.progress-spinner.size-sm {
    width: 25px !important;
    height: 25px !important;
}

/*stili radio e checkbox disabilitate*/
.form-check [type=checkbox]:disabled + label::after {
    background-color: var(--adi-gray-500) !important;
    border-color: #C3C5CB !important;
    opacity: 0.8;
}

.form-check [type=radio]:disabled + label::after {
    border-color: #C3C5CB !important;
    background-color: var(--adi-gray-500) !important;
}

.form-check [type=radio]:disabled + label::before {
    border-color: #C3C5CB !important;
}

.collapse-div.collapse-left-icon .collapse-header [data-toggle=collapse]:before {
    color: var(--prymary);
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%)!important;
    margin: 0 1rem 0 0;
}

.bottoniLayout {
    background-color: var(--adi-gray-100);
    box-shadow: 0 -2px 0 rgb(220 220 220);
    /* bottom: 0; */
    /* position: sticky;
    bottom: 0;
    box-shadow: 0 -2px 0 rgb(220 220 220);
    z-index: 5;
    background-color: var(--adi-gray-100);
    padding: 0 15px; */
}

.collapse-body .collapse-header button[aria-expanded=true]:before {
    height: 21px !important;
    width: 27px !important;
}

.collapse-header [data-toggle=collapse] {
    padding: 0px!important;
}

.list-group-item.disabled {
    color: #656566 !important;
}

.card:after {
    all: unset !important;
}

.list-group-item.disabled {
    color: var(--black) !important;
    opacity: 0.7;
}

.customScrollbarStyle::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0.5);
}

.customScrollbarStyle::-webkit-scrollbar {
    width: 14px;
}

.customScrollbarStyle::-webkit-scrollbar-track {
    background: #F6F6F6;
}

.customScrollbarStyle::-webkit-scrollbar-thumb {
    background-color: #C5C5C5;
    border-radius: 20px;
    border: 3px solid #F6F6F6;
}
