.btnAnnullaStyle {
    width: 11vw !important;
    min-width: 130px !important;
    height: 2.5rem !important;
    font-size: 14px;
}

.separator {
    background-color: var(--prymary);
    height: 4px;
    border-radius: 10px;
    margin: auto;
}
.info {
    text-transform:uppercase;
    color: var(--prymary);
    font-weight: 700;
}

.container {
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    min-height: calc(100vh - 15rem);
}
