.foregroundMode {
    background-color: white;
}

.required:after {
    content: "*";
    color: #BF023D;
} 

.labelRisultati {
    color: var(--prymary);
    font-weight: 700;
    font-size: 14px;
}

