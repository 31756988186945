.mandatory {
    font-size: 14px;
    color: #D83D3D;
    background: #fff;
    font-style: italic;
    padding: 0 2px!important;
}

.autocomplete {
    border: 9px solid #999;
    padding: 0.5rem;
    width: 300px;
}

.noSuggestions {
    color: var(--adi-red);
    font-size: 12px;
    position: absolute;
    padding-left: 0.5rem;
    padding-top: 0.1rem;
}

.suggestions {
    border-top-width: 0 !important;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
    color: var(--prymary);
}

.suggestions li {
    padding: 0.5rem;
}

.suggestionActive {
    background-color: white;
    cursor: pointer;
    border-bottom: 0 !important;
}

.suggestionSelected {
    background-color: var(--prymary);
    color: var(--white);
    cursor: pointer;
    border-bottom: 0 !important;
    text-decoration: underline;
}

.suggestions li:hover {
    background-color: var(--prymary);
    color: white;
    cursor: pointer;
    border-bottom: 0 !important;
    text-decoration: underline;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
}

.emptySuggestions {
    border-top-width: 0 !important;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
}

.noOptionsMessageInDropdown {
    font-size: 14px !important;
    color: #99999F !important;
    text-align: center !important;
    padding: 0.5rem !important;
}
