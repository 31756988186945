.headerContainer {
    height: 55px;
    background-color: var(--adi-gray-300);
    padding: 0 30px;
    color: var(--white);
}

.styleHeader {
    color: #19191A;
    font-size: 18px;
}

.headerImg {
    width: 208px;
    height: 24px;
}

.stileProfilo {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    flex-direction: row;
}

.labelNomeApplicazione {
    color: var(--white);
    font-size: 20px;
}

/* risoluzione per dispositivi con schermo small ed extrasmall */
@media screen and (max-width: 576px) {
    .headerImg {
        width: 120px;
    }

    .labelNomeApplicazione {
        white-space: break-spaces;
    }
}

/* risoluzione per dispositivi con schermo medium */
@media screen and (min-width: 577px) and (max-width: 768px) {
    .headerImg {
        width: 160px;
    }
}
.nomeUtente,
.profiliUtente > span {
    max-width: 300px;
}

@media screen and (max-width: 1400px) {
    .profiliUtente, .utenteLoggato {
        font-size: 10px !important;
    }
    .nomeUtente,
    .profiliUtente > span {
        max-width: 150px;
    }
}

.profiliUtente {
    text-overflow: ellipsis !important;
    display: -webkit-box;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden;
}
