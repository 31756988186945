.modalBtnStyle {
    width: 14vw !important;
    height: 2.5vw !important;
}

.modalGreen {
    border: 0.1vw solid var(--prymary) !important;
    color: var(--prymary) !important;
    font-weight: bold;
}

.modalGreen:active, .modalGreen:hover, .modalGreen:focus {
    background-color: var(--prymary) !important;
    color: #FFFFFF !important;
}

.buttonProseguiConsultazione {
    background-color: var(--prymary) !important;
    color: #FFFFFF !important;
}

.backgroundGreen {
    background-color: var(--prymary) !important;
    color: #FFFFFF;
    font-size: 18px;
}

.availableCharsLabel {
    opacity: 0.7;
    font-size: 14px;
    text-align: right;
    position: absolute;
    right: 7px;
}
