.aggiungiFiguraRichiesta{
    fill: var(--prymary);
    color: var(--prymary);
}
.disabledAggiungiFiguraRichiesta{
    fill: grey;
    color: grey;
}
.eliminaFiguraRichiesta{
   fill: var(--prymary);
    margin-left: 6px;
    margin-bottom: 7px;
}

.eliminaFiguraRichiestaDisabilitato{
    background: none;
    color: grey;
    fill: grey;
    cursor: default;
    pointer-events: none;
    vertical-align: baseline;
    margin-left: 6px;
    margin-bottom: 7px;
}

.popoverElimina{
    z-index: 8;
}

.title{
    color: var(--prymary) !important;
    font-size: 14px;
    font-weight: 800;
}

.bottomPlaceholder {
    font-size: 14px;
    color: var(--black);
    font-weight: 400;
}

.attivaProfessionaleContainer{
    min-height: 768px;
}