.foregroundMode {
    background-color: white;
}

.required:after {
    content: "*";
    color: #BF023D;
} 
.infoText {
    font-size: small;
}

.modalHeader {
    color: white;
    font-weight: bold;
    padding: 0.8vw !important;
    font-size: 1.1vw !important;
}
