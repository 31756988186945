.containerSezioneQuestionari {
    margin-left: 5vw;
    margin-right: 5vw;
}

.descrizioneQuestionario {
    text-align: center;
    font-weight: bold;
}

.requiredFields {
    color: var(--adi-red);
    font-weight: 500;
}