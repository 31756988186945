.stickBottom{
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    margin: auto;
    width: 95%;
    align-content: stretch;
    justify-content: stretch;
    flex-grow: 3;
    min-width: 555px;
}

.buttonsBoxStickContainer{
    position: relative;
    margin-top: 10vh;
}