.customBorderLeft {
    border-left: 2px solid var(--prymary);
    height: 70px;
}

.customMarginTop {
    margin-top: 0;
}

@media screen and (max-width: 780px) {
    .customMarginTop {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}
