.headerTitle {
    color: black;
    font-weight: bold;
    padding-left: 0 !important;
    cursor: pointer;
    padding-bottom: 0 !important;
}

.radioCheckboxGroup {
    color: var(--prymary);
    font-weight: bold;
}

.customCheckboxLabel {
    color: #176A65 !important;
    font-weight: bold !important;
    font-size: 12px !important;
}
