.h6 {
    border-bottom: 5px solid #999;
}

.fieldContainer {
    height: 5rem;
}

.mandatoryColor {
    color: var(--adi-red)
}

form {
    width: 300px;
}


.intl-tel-input {
    display: table-cell;
}

.intl-tel-input .selected-flag {
    z-index: 4;
}

.intl-tel-input .country-list {
    z-index: 5;
}

.input-group .intl-tel-input .form-control {
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
}
