.inputLabel {
    color: black;
    margin-bottom: 0 !important;
}

.infoInput {
    font-size: 0.7vw !important;
}

.campoObbligatorio {
    color: var(--adi-red)
}

.required:after {
    content: "*";
    color: #BF023D;
} 