.tooltips  {
    background: var(--prymary);
    color: var(--white);
    padding: 10px;
    font-size: 0.8em;
    border-radius: 5px;
}

.textPopover{
    text-decoration: none;
    cursor: pointer;
}

.popover-ellipsis {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;
    height: 25px;
    display: block;
}

.popover {
    z-index: 99 !important;
}

.arrow {

}

