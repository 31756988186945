.h5custom {
  line-height: 4;
  font-style: normal;
  font-variant: normal;
  font-size: 14px;
  font-weight: 600;
  background: var(--prymary);
  color: var(--white);
  text-align: center;
}

.clickDisabled {
  pointer-events: none!important;
}

.transform {
  -webkit-transform:scale(0.9);
  -moz-transform:scale(0.9);
  -ms-transform:scale(0.9);
  transform:scale(0.9);
  -ms-transform-origin:0 0;
  -webkit-transform-origin:0 0;
  -moz-transform-origin:0 0;
  transform-origin:0 0;
}

.containerConsenso {
  text-align: center;
  border-left: 3px solid var(--prymary) !important;
  border-right: 3px solid var(--prymary) !important;
}

.containerModale {
  /*border: 3px solid var(--prymary) !important;*/
  height: 30vh !important;
  width: 30vw !important;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.75;
  width: 100vw;
  height: 100vh;
}

.containerOscura {
  text-align: center;
  border-left: 3px solid #986f00 !important;
  border-right: 3px solid #986f00 !important;
}

.containerViola {
  text-align: center;
  border-left: 3px solid #bf023d !important;
  border-right: 3px solid #bf023d !important;
}

.buttons {
  width: 100% !important;
}

.buttonAnnulla {
  width: 50% !important;
  font-size: 14px !important;
}

.buttonOk {
  font-size: 14px !important;
  width: 50% !important;
}

.modalContentCustom {
  /*min-width: 500px;*/
  text-align: center !important;
}

.modalContainerCustom {
  width: 100% !important;
}

.modalBodyCustom{
  text-align: left !important;
  font-size: 0.8rem !important;
}

.modalConsenso {
  height: 100%;
  border: 2px solid var(--prymary);
}

.modalHeader {
  width: 100%;
  background-color: var(--prymary);
  color: white;
  border-radius: 0;
}

.modalHeader h5 {
  width: 100%;
  background-color: var(--prymary);
  height: 50px;
  color: white;
  font-size: 14px;
  line-height: 3;
}

.formGroup.form-group{
  border: 3px solid var(--prymary);
  height: 10px !important;
}

.btnAnnulla {
  margin-bottom: 9px;
  font-size: 12px;
  width: 50%;
  margin-right: 5px;
  border: 1px solid;
}


.btnConsenso {
  width: 50%;
  margin-left: 5px;
  font-size: 14px;
  height: 60%;
}

.modalFooter {
  border-right: 3px solid var(--prymary);
  border-left: 3px solid var(--prymary);
  border-bottom: 3px solid var(--prymary);
  border-radius: 0;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.triangle-down-modal-consenso {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 25px solid var(--prymary);
  margin: 0 auto;
}

/* Modale oro oscura doc*/

.modalOscura {
  height: 100%;
  border: 2px solid #986f00;
}

.modalHeaderOscura {
  width: 100%;
  background-color: #986f00;
  height: 50px;
  color: white;
  border-radius: 0;
}

.modalHeaderOscura h5 {
  background-color: #986f00;
  width: 100%;
  height: 50px;
  color: white;
  font-size: 14px;
  line-height: 3;
}

.modalBodyOscura {
  border-right: 3px solid #986f00;
  border-left: 3px solid #986f00;
}

.triangle-down-modal-oscura {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 25px solid #986f00;
  margin: 0 auto;
}

.modalFooterOscura {
  border-right: 3px solid #986f00;
  border-left: 3px solid #986f00;
  border-bottom: 3px solid #986f00;
  border-radius: 0;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.btnConsensoOscura {
  width: 50%;
  margin-left: 5px;
  font-size: 14px;
  height: 60%;
  background-color: #986f00;
  border: 0px;
  outline: 0;
}

.btnConsensoOscura:hover {
  width: 50%;
  margin-left: 5px;
  font-size: 14px;
  height: 60%;
  background-color: #986f00;
  border: 0px;
}

.btnConsensoOscura:active {
  width: 50%;
  margin-left: 5px;
  font-size: 14px;
  height: 60%;
  background-color: #986f00 !important;
  border: 1px solid #986f00 !important;
}

.btnConsensoOscura:focus {
  outline: 0;
  -webkit-box-shadow: none !important;
  background-color: #986f00;
  box-shadow: inset 0 0 0 2px #986f00;
  background-color: #986f00 !important;
  color: white !important;
  border: 1px solid #986f00;
}

.btnAnnullaOscura {
  margin-bottom: 9px;
  font-size: 12px;
  width: 50%;
  margin-right: 5px;
  border: 1px solid;
  box-shadow: inset 0 0 0 2px #986f00;
  color: #986f00;
}

.btnAnnullaOscura:hover {
  margin-bottom: 9px;
  font-size: 12px;
  width: 50%;
  margin-right: 5px;
  border: 1px solid #986f00;
  box-shadow: inset 0 0 0 2px #986f00;
  color: #986f00;
}

.btnAnnullaOscura:focus {
  outline: 0;
  -webkit-box-shadow: none !important;
  margin-bottom: 9px;
  font-size: 12px;
  width: 50%;
  margin-right: 5px;
  border: 1px solid #986f00;
  box-shadow: inset 0 0 0 2px #986f00;
  color: #986f00 !important;
}

/* Modale VIOLA*/

.modalViola {
  height: 100%;
  border: 2px solid #bf023d;
}

.modalHeaderViola {
  width: 100%;
  background-color: #bf023d;
  height: 50px;
  color: white;
  border-radius: 0;
}

.modalHeaderViola h5 {
  background-color: #bf023d;
  width: 100%;
  height: 50px;
  color: white;
  font-size: 14px;
  line-height: 3;
}

.modalBodyViola {
  border-right: 3px solid #bf023d;
  border-left: 3px solid #bf023d;
}

.triangle-down-modal-viola {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 25px solid #bf023d;
  margin: 0 auto;
}

.modalFooterViola {
  border-right: 3px solid #bf023d;
  border-left: 3px solid #bf023d;
  border-bottom: 3px solid #bf023d;
  border-radius: 0;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.btnAnnullaViola {
  margin-bottom: 9px;
  font-size: 12px;
  width: 50%;
  margin-right: 5px;
  border: 1px solid;
  box-shadow: inset 0 0 0 2px #bf023d;
  color: #bf023d;
}

.btnAnnullaViola:hover {
  margin-bottom: 9px;
  font-size: 12px;
  width: 50%;
  margin-right: 5px;
  border: 1px solid #bf023d;
  box-shadow: inset 0 0 0 2px #bf023d;
  color: #bf023d;
}

.btnAnnullaViola:focus {
  outline: 0;
  -webkit-box-shadow: none !important;
  margin-bottom: 9px;
  font-size: 12px;
  width: 50%;
  margin-right: 5px;
  border: 1px solid #bf023d;
  box-shadow: inset 0 0 0 2px #bf023d;
  color: #bf023d !important;
}

.btnConsensoViola {
  width: 50%;
  margin-left: 5px;
  font-size: 14px;
  height: 60%;
  background-color: #bf023d;
  border: 0px;
  color: white;
}

.btnConsensoViola:hover {
  width: 50%;
  margin-left: 5px;
  font-size: 14px;
  height: 60%;
  background-color: #bf023d;
  border: none;
  color: white;
  box-shadow: var(--adi-gray-dark);
}

.btnConsensoViola:active {
  color: white;
  outline: 0;
}

.btnConsensoViola:focus {
  background-color: #bf023d;
  outline: 0;
  box-shadow: none;
  -webkit-box-shadow: none !important;
}

/* PULSANTI CSS */

.btnGreenOutline {
  
  background-color: var(--white);
  border: 3px solid var(--prymary);
  color: var(--prymary) !important;
  box-shadow: none;
}

.btnGreenOutline:hover {
  
  background-color: #deecf2;
  border: 3px solid #0e3f3b;
  color: #0e3f3b !important;
  box-shadow: none;
}

.btnGreenOutline:active {
  background-color: #deecf2;
  border: 3px solid #0e3f3b;
  color: #084662 !important;
  box-shadow: none;
}

.btnGreenOutline:focus {
  
  background-color: #deecf2;
  border: 3px solid #0e3f3b;
  color: #0e3f3b !important;
  box-shadow: none;
  -webkit-box-shadow: none !important;
}

.btnGreenOutline:disabled {
  
  background-color: var(--white);
  border: #d6d6d6;
  color: var(--adi-gray-dark) !important;
}

/* BTN PERICOLO VIOLA */
.btnViola {
  
  background-color: #bf023d !important;
  color: var(--white) !important;
  border: 1px solid #bf023d !important;
  box-shadow: none;
}

.btnViola:hover {
  
  background-color: #9f0636 !important;
  color: var(--white) !important;
  border: 1px solid #9f0636 !important;
  box-shadow: none;
}

.btnViola:active {
  
  background-color: #890a32 !important;
  color: var(--white) !important;
  border: 1px solid #890a32 !important;
}

.btnViola:focus {
  
  background-color: #bf023d !important;
  color: var(--white) !important;
  border: 1px solid #890a32 !important;
  outline: 0;
  box-shadow: none;
  -webkit-box-shadow: none !important;
}

.btnViola:disabled {
  
  background-color: #d6d6d6;
  color: var(--white) !important;
  border: #d6d6d6;
}

/* BTN PERICOLO VIOLA OUTLINE */
.btnOutlineViola {
  
  background-color: var(--white);
  border: 3px solid #bf023d;
  color: #bf023d !important;
  box-shadow: none;
}

.btnOutlineViola:hover {
  
  background-color: #f8eef1;
  border: 3px solid #9f0636;
  color: #9f0636 !important;
  box-shadow: none;
}

.btnOutlineViola:active {
  
  background-color: #f6e2e8;
  border: 3px solid #890a32;
  color: #890a32 !important;
}

.btnOutlineViola:focus {
  
  background-color: #f8eef1;
  border: 3px solid #9f0636;
  color: #9f0636 !important;
  box-shadow: none;
  -webkit-box-shadow: none !important;
}

.btnOutlineViola:disabled {
  
  background-color: var(--white);
  border: #d6d6d6;
  color: var(--adi-gray-dark) !important;
}

/* BTN WARNING GIALLO */

.btnWarning {
  
  background-color: #986f00 !important;
  color: var(--white) !important;
  border: 1px solid #986f00 !important;
  box-shadow: none;
}

.btnWarning:hover {
  
  background-color: #886300 !important;
  color: var(--white) !important;
  border: 1px solid #886300 !important;
  box-shadow: none;
}

.btnWarning:active {
  
  background-color: #6f5100 !important;
  color: var(--white) !important;
  border: 1px solid #6f5100 !important;
  box-shadow: none;
}

.btnWarning:focus {
  
  background-color: #986f00 !important;
  color: var(--white) !important;
  border: 1px solid #6f5100 !important;
  outline: 0;
  box-shadow: none;
  -webkit-box-shadow: none !important;
}

.btnWarning:disabled {
  
  background-color: #d6d6d6;
  color: var(--white);
  border: #d6d6d6;
}

/* BTN WARNING OUTLINE */
.btnWarningOutline {
  
  background-color: var(--white);
  border: 3px solid #986f00;
  color: #986f00 !important;
  box-shadow: none;
}

.btnWarningOutline:hover {
  
  background-color: #f2ecde;
  border: 3px solid #886300;
  color: #886300 !important;
  box-shadow: none;
}

.btnWarningOutline:active {
  
  background-color: #ede3c9;
  border: 3px solid #6f5100;
  color: #6f5100 !important;
}

.btnWarningOutline:focus {
  
  background-color: #f2ecde;
  border: 3px solid #886300;
  color: #886300 !important;
  box-shadow: none;
  -webkit-box-shadow: none !important;
}

.btnWarningOutline:disabled {
  
  background-color: var(--white);
  border: #d6d6d6;
  color: var(--adi-gray-dark);
}
