.titleSezione {
    color: var(--black)!important;
    font-size: 14px;
    margin: 0px;
}

.titleSezioneNotAccordion {
    font-weight: 800;
}

.requiredFields {
    color: var(--adi-red);
    font-weight: 500;
    margin: 0px;
    margin-right: 10px;
}

.sezioneAccordionBody {
    border: 1px solid #e3e4e6;
    box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 51%);
    border-radius: 22px;
}
