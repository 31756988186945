.navContainer {
    background-color: var(--prymary)!important;
    height: 60px;
    color: #19191A;
    padding: 0 30px 0 30px;
}
.navLink{
    color: white;
}

.hamburgerNav {
    background-color: var(--prymary) !important;
    padding: 0.5px 30px !important;
  }

.icohamburgerNav{
    fill: var(--white) !important;
}
.icohamburgerNav:hover{
    background-color: var(--white) !important;
    fill: var(--prymary) !important;
}

  .navbarCollapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    margin-top: -10px;
    margin-bottom: -20px;
  }
  

.styleSecondaFascia {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    background-color: var(--prymary);
    height: 58px;
    color: #19191A;
    font-size: 33px;
}

.navInfo {
    flex-grow: 8;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


.navCustom {
    padding: 0;
}

.labelCustom {
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: none;
} 

.liPagina {
    color: var(--white);
    background: var(--prymary);
    display: block;
    float: left;
    padding: 7px 23px;
    border-radius: 10px;
    position: relative;
    text-decoration: none;
    justify-content: left;
    text-align: center;
}

.activePage {
    background: var(--white);
    color: var(--prymary);
}

.activePage:hover {
    background: var(--adi-primary-hover);
    cursor: pointer;
    color: var(--white);
}

.stylePagineLi:hover {
    background: var(--adi-primary-hover);
    cursor: pointer;
}

.liLink {
    color: var(--prymary);
    height: 60px;
    display: block;
    float: left;
    padding-top: 10px;
    padding-left: 24px;
    padding-bottom: 24px;
    position: relative;
    text-decoration: none;
    justify-content: left;
}

.liLink:hover {
    text-decoration: underline;
    background: var(--adi-primary-hover);
}

.actionIcon {
    vertical-align: top;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 7.3px;
}

.actionIcon:hover {
    background: var(--adi-primary-hover);
    text-decoration: none !important;
}

.caption {
    display: block;
    font-size: 14px;
    color: var(--white);
    padding-top: 0.14rem;
}

.caption:hover {
    color: black;
    text-decoration: none !important;
}

.actionIconInBurgerMenu {
    vertical-align: top;
    display: inline-block;
    cursor: pointer;
}

.separatorVerticalBar {
    border: 0.5px solid var(--adi-gray-dark);
    padding-top: 8px;
    padding-bottom: 15px;
}

.filtroRicercaAvanzata {
    width: 20% !important;
    height: 40px !important;
    vertical-align: text-top;
}

.btnAnnullaStyle {
    width: 15vw;
    min-width: 130px;
    height: 2.2rem;
    font-size: 14px;
    background-color: var(--white);
    border: none;
    color: var(--prymary);
    font-weight: bold;
}

.searchButton {
    float: right;
    margin-right: 2%;
}

/* 992px è lo schermo lg*/
@media screen and (max-width: 992px) and (min-width: 475px) {
    .btnAnnullaStyle {
        width: 25vw !important;
    }
}

.nonLette {
    float: right;
    margin-top: 5%;
    height: 20px;
    width: 20px;
    background-color: #FF7373;
    border-radius: 50%;
    font-size: 14px;
    color: white;
}

.iconLink {
    text-decoration: none !important;
}

.popoverNavBar{
    top: 107px!important;
}

@media (max-width: 1199px){
    .liPagina {
        padding: 10px 15px;
    }
}




