.azTableCellTwo {
    /*border: none !important;*/
    font-size: 14px;
    text-align: center;
    height: 40px;
    padding: 5px;
    min-width: 108px;

}

.title {
    color: var(--prymary) !important;
    font-size: 14px;
    font-weight: 800;
}

.tableContainer {
    border: 1px solid #176A65 !important;
    border-bottom: 1px solid #176A65 !important;
    border-radius: 10px!important;
}

.link {
    font-size: 14px;
    line-height: 20px;
    color: var(--prymary) !important;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline!important;
}

.popoverStoricoProfessionista {
    width: initial !important;
}
