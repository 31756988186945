.requiredFields {
    color: var(--adi-red);
    font-weight: 500;
}

.risposta:nth-child(odd) {
    background-color: #f2f2f2 !important;
}

.descriptionPunteggio {
    color: gray !important;
    text-align: justify;
}

.punteggi {
    font-size: 12px;
    margin-top: -6px;
    color: black;
    font-style: italic;
    opacity: .5;
    margin-bottom: 6px;
}
