.azTable {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    overflow: auto;
}

.azTableHeader {
    color: var(--prymary);
    border-top: 3px solid var(--prymary);
    width: 15%;
    background: white;

}

.azTableHeaderCell {
    min-width: 100px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    font-size: 14px !important;
    color: var(--prymary);
}


.azTableHeaderCellMinWidth {
    cursor: pointer;
    font-size: 14px !important;
    color: var(--prymary);
    min-width: 108px !important;
    text-align: center !important;
    height: auto;
    padding: 1rem 5px 1rem 5px;
}

.azTableHeaderCellMinWidthRicercaAssistito {
    cursor: pointer;
    font-size: 14px !important;
    color: #a8a8a8;
    min-width: 108px !important;
    text-align: center !important;
}


.azTableHeaderCellMinWidthHomePage {
    cursor: pointer;
    font-size: 14px !important;
    color: var(--prymary);
    min-width: 108px !important;
    text-align: center !important;
    height: auto;
    padding: 0.5rem 5px 0.5rem 5px;
}

.azTableCell {
    font-size: 12px;
    text-align: center;
    height: 40px;
    padding: 5px;
    min-width: 50px;
    vertical-align: middle !important;
    color: black;

    /* Ellipsis su tutte le celle della tabella */
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.azTableCellTwo {
    font-size: 14px;
    text-align: center;
    height: 40px;
    padding: 5px;
    min-width: 108px;
    vertical-align: middle !important;

}

.azTableCellDropdown {
    font-size: 14px;
    text-align: center;
    height: 40px;
    padding: 5px;
    min-width: 108px;
    overflow: visible;
    vertical-align: middle !important;
}

.azTableCellCheckbox {
    text-align: center;
    height: 40px;
    padding: 0 !important;
    margin-top: 0 !important;
    vertical-align: middle !important;
}


.paginationContainer {
    float: right;
}

.hideScrollbar::-webkit-scrollbar {
    display: none;
}

.hideScrollbar {
    margin: auto
}

.legendContainer {
    float: left;
    min-height: 74px;
    padding-top: 16px;
}

.stickerGreenOp4{
    color:var(--prymary);
    opacity: 0.4;
}

.stickerGreen {
    /* width: 20px;
    height: 20px;
    background-color: var(--prymary);
    border-radius: 85%;
    border: 2px solid var(--prymary);
    margin: auto; */
    color:var(--prymary);
}

.stickerYellow {
    /* width: 20px;
    height: 20px;
    background-color: #F4B740;
    border-radius: 85%;
    border: 2px solid #F4B740;
    margin: auto; */
    color:var(--adi-yellow-100);
}

.stickerRed {
    /* width: 20px;
    height: 20px;
    background-color: var(--adi-red);
    border-radius: 85%;
    border: 2px solid var(--adi-red);
    margin: auto; */
    color: var(--adi-red);
}

.stickerFillWarning {
    /* width: 22px;
    height: 22px;
    color: white;
    fill: white;
    font-weight: bold;
    padding-left: 40%;
    padding-top: 10%;
    cursor: default; */
}

.stickerFillVerificato {
    /* width: 22px;
    height: 22px;
    color: white;
    fill: white;
    font-weight: bold;
    padding-left: 23%;
    padding-top: 10%;
    cursor: default; */
}

.stickerFill:hover {
    text-decoration: none;
}

.stickerGrey {
    width: 18px;
    height: 18px;
    background-color: grey;
    border-radius: 85%;
    border: 2px solid grey;
    margin: auto;
}

.stickerTrattino {
    width: 10px;
    background-color: black;
    border-radius: 85%;
    border: 1px solid black;
    margin: auto;
}

@media screen and (max-width: 600px) {
    .azTable {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid;
    }


}

@media screen and (max-width: 1200px) {
    .azTableCellTwo {
        /*border: none !important;*/
        font-size: 14px;
        text-align: center;
        height: 40px;
        /* padding-right: 20px; */
        min-width: 108px;
    }
}

@media screen and (max-width: 1480px) {
    .azDiv {
        overflow-x: auto;
    }
}

.scrollCols {
    overflow-x: auto;
}

/*mettere tutte le specifiche per IE qui dentro*/
@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    th {
        font-weight: bold;
    }
}

.tableTitle {
    font-weight: bold;
    margin-bottom: 1rem;
}

.informationLegend {
    margin-top: 5rem;
    margin-left: 1rem;
}

.bgColorWhite {
    background-color: white !important;
}

.ellipsisLabel {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.linkToTd {
    cursor: pointer;
    text-decoration: underline;
    color: var(--prymary);
    background: transparent;
    border: none;
}

.disabledCursor {
    cursor: default;
}
