.overlayBackground {
    position: absolute;
    width: 94%;
    background-color: black;
    opacity: 0.5;
    z-index: 1060;
}

.contentBackground {
    position: absolute;
    background-color: white;
    width: 94%;
    z-index: 1060;
}

.content {
    margin: 2%;
}