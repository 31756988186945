.struttura {
    border: 3px solid var(--prymary);
    font-size: 14px;
    background: white !important;
    outline: 0;
    height: 2.5rem !important;
    width: 100%;
    font-weight: normal;
    color: var(--prymary);
}

.struttura:disabled {
    border: 3px solid var(--prymary);
    font-size: 14px;
    background: white !important;
    outline: 0;
    height: 2.5rem !important;
    width: 100%;
    font-weight: normal;
    color: var(--prymary);
    opacity: 0.5 !important;
}

