.show {
    display: block;
}

.fade {
    opacity: 0;
    -webkit-transition: opacity 1.5s linear;
    -o-transition: opacity 1.5s linear;
    transition: opacity 1.5s linear;
}

.fade.in {
    opacity: 1;
}
